import fetchAPI from '../../../utils/fetchAPI';
import { TSellerProfileClient } from 'types/TSellerProfile';

type TParams = {
  sellerId: number;
  requestExternalReviews?: boolean;
};

export default function getSellerProfile({ sellerId, requestExternalReviews }: TParams): Promise<TSellerProfileClient> {
  try {
    return fetchAPI({
      url: `/v/api/seller-profile/${encodeURIComponent(sellerId)}${requestExternalReviews ? '?reviews=external' : ''}`,
    });
  } catch (e) {
    return Promise.resolve({}) as Promise<TSellerProfileClient>;
  }
}
