import React from 'react';
import { Icon } from '@hz-design-system/web-ui';

import Classes from './SellerInfo.scss';

type TSellerResponseRateProps = {
  label: string;
};

const SellerResponseRate = ({ label }: TSellerResponseRateProps) => {
  if (!label) {
    return null;
  }

  return (
    <div className={Classes.section}>
      <div className={Classes.rowWithIcon}>
        <span>
          <Icon name="messages" color="text-secondary" className={Classes.icon} />
        </span>
        <span>{label}</span>
      </div>
    </div>
  );
};

export default SellerResponseRate;
