import React from 'react';
import { ButtonsBlock, TertiaryButton, ButtonIcon } from '@hz-design-system/web-ui';
import { useI18nContext } from '@ecg-marktplaats/js-react-i18n';

import useToggle from '../../../hooks/useToggle';
import Classes from './DescriptionButton.scss';

const DescriptionButton = () => {
  const { t } = useI18nContext();
  const [isCollapsed, toggle] = useToggle(true, '[data-collapse-target="description"]', 'data-collapsed');

  return (
    <ButtonsBlock className={`${Classes.root} ${isCollapsed ? Classes.isCollapsed : ''}`}>
      <TertiaryButton onClick={toggle}>
        <ButtonIcon name={isCollapsed ? 'arrow_down' : 'arrow_up'} isStartIcon />
        {isCollapsed ? t('listing.description.expand') : t('listing.description.collapse')}
      </TertiaryButton>
    </ButtonsBlock>
  );
};

export default DescriptionButton;
