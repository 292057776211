import React, { useContext, useState } from 'react';
import { useI18nContext } from '@ecg-marktplaats/js-react-i18n';
import { SecondaryIconButton } from '@hz-design-system/web-ui';

import toggleFollowSeller from '../../repositories/toggleFollowSeller';
import EnvironmentContext from '../../../../contexts/EnvironmentContext';
import LoginDialogContainer from '../../../../components/LoginDialog/LoginDialogContainer';
import delayLoginReload from '../../../../components/LoginDialog/utils/delayLoginReload';

import { TListingSellerInfo } from 'types/TListing';

import Classes from './SellerFollowButton.scss';
import { trackGAEvent } from '../../../../utils/gaTracking';

type TSellerInfoProps = {
  sellerId: TListingSellerInfo['id'];
  isSaved: TListingSellerInfo['isSaved'];
};

const followSellerBeginEvents = {
  label: 'FollowUserBegin',
};
const followSellerSuccessEvents = {
  label: 'FollowUserSuccess',
};
const unFollowSellerBeginEvents = {
  label: 'UnfollowUserBegin',
};
const unFollowSellerSuccessEvents = {
  label: 'UnfollowUserSuccess',
};

const getGaEventsForBegin = (isFollowing) => (isFollowing ? unFollowSellerBeginEvents : followSellerBeginEvents);
const getGaEventsForSuccess = (isFollowing) => (isFollowing ? unFollowSellerSuccessEvents : followSellerSuccessEvents);

const trackGaEventForBegin = (isFavourite) => {
  const { label } = getGaEventsForBegin(isFavourite);
  trackGAEvent(label);
};

const trackGaEventForSuccess = (isFavourite) => {
  const { label } = getGaEventsForSuccess(isFavourite);
  trackGAEvent(label);
};

const SellerFollowButton = ({ sellerId, isSaved }: TSellerInfoProps) => {
  const { t } = useI18nContext();
  const { userLoggedIn, xsrfToken } = useContext(EnvironmentContext);
  const [isFollowing, setIsFollowing] = useState(isSaved);
  const [showLoginDialog, setShowLoginDialog] = useState(false);

  const toggleFollow = async () => {
    const newState = !isFollowing;
    // optimistic update
    setShowLoginDialog(false);
    setIsFollowing(newState);

    trackGaEventForBegin(isFollowing);

    const [{ followSeller }, { default: showToastNotification }] = await Promise.all([
      toggleFollowSeller({ sellerId, xsrfToken, follow: newState }),
      import(/* webpackChunkName: "toastNotification" */ '../../../../components/Toast/ToastNotification'),
    ]);

    // success state
    if (isFollowing !== followSeller) {
      trackGaEventForSuccess(isFollowing);

      showToastNotification({
        type: 'success',
        description: t(isFollowing ? 'sellerInfo.notifications.unfollow' : 'sellerInfo.notifications.follow'),
      });
    } else {
      showToastNotification({
        type: 'error',
        description: t('error.generic'),
      });
    }

    // actual update
    setIsFollowing(followSeller);
    await delayLoginReload();
  };

  const handleClick = async () => {
    if (userLoggedIn) {
      await toggleFollow();
    } else {
      setShowLoginDialog(true);
    }
  };

  const handleSuccess = async () => {
    await toggleFollow();
    document.location.reload();
  };

  return (
    <>
      <SecondaryIconButton
        className={Classes.root}
        aria-label={isFollowing ? t('sellerInfo.unfollow') : t('sellerInfo.follow')}
        title={isFollowing ? t('sellerInfo.unfollow') : t('sellerInfo.follow')}
        name={isFollowing ? 'following' : 'follow'}
        onClick={handleClick}
      />
      {showLoginDialog && <LoginDialogContainer onClose={setShowLoginDialog} onSuccess={handleSuccess} />}
    </>
  );
};

export default SellerFollowButton;
