import React from 'react';
import { useI18nContext } from '@ecg-marktplaats/js-react-i18n';
import { Icon } from '@hz-design-system/web-ui';

import Classes from './Stats.scss';
import { TListingClient } from 'types/TListing';

type TStatsProps = {
  stats: TListingClient['stats'];
  withDivider?: boolean;
};

const Stats = ({ stats, withDivider }: TStatsProps) => {
  const { favoritedCount, viewCount, since } = stats;
  const { t, date } = useI18nContext();

  return (
    <div className={`${Classes.root} ${withDivider ? Classes.withDivider : ''}`}>
      <span className={Classes.stat}>
        <Icon name="eye-open" color="text-secondary" />
        <span className={Classes.summary}>{viewCount}</span>
      </span>

      <span className={Classes.stat}>
        <Icon name="heart" color="text-secondary" />
        <span className={Classes.summary}>{favoritedCount}</span>
      </span>

      <span className={Classes.stat}>
        <Icon name="clock" color="text-secondary" />
        <span className={Classes.summary}>
          <span className={Classes.dateExtra}>{`${t('stats.since')} `}</span>
          {date(new Date(since), "D MMM. 'YY, HH:mm")}
        </span>
      </span>
    </div>
  );
};

export default Stats;
