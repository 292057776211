import { BasicModal } from '@hz-design-system/web-ui';
import React, { useEffect } from 'react';
import { useI18nContext } from '@ecg-marktplaats/js-react-i18n';
import Classes from './LoginDialogContainer.scss';

type TLoginDialogAppProps = {
  onClose: Function;
  onSuccess: Function;
};

const LOGIN_IFRAME_NAME = 'loginIframe';

const LOGIN_IFRAME_EVENTS = {
  Closed: `${LOGIN_IFRAME_NAME}.closed`,
};

const LoginDialogContainer = ({ onClose, onSuccess }: TLoginDialogAppProps) => {
  const { t } = useI18nContext();

  useEffect(() => {
    const onMessage = (evt: MessageEvent) => {
      switch (evt.data?.type) {
        case LOGIN_IFRAME_EVENTS.Closed:
          onSuccess();
          onClose();
          break;
      }
    };
    window.addEventListener('message', onMessage);

    return () => window.removeEventListener('message', onMessage);
  }, [onSuccess]);
  return (
    <BasicModal title={t('modal.login.title')} contentClassName="u-paddingNone" onClose={() => onClose()} size="s">
      <iframe
        name={LOGIN_IFRAME_NAME}
        className={Classes.iframe}
        src={'/identity/v2/login?mpEmbeddedInMobileApp=true'}
      />
    </BasicModal>
  );
};

export default LoginDialogContainer;
