export const validTenantsKeys = {
  mp: 'mp',
  twhBe: 'twhBe',
  twhFr: 'twhFr',
};

export const tenants = {
  mp: 'mp',
  twh: 'twh',
};

export const priceTypes = {
  FIXED: 'FIXED',
  FREE_BID: 'FREE_BID',
  MIN_BID: 'MIN_BID',
  EXCHANGE: 'EXCHANGE',
  FAST_BID: 'FAST_BID',
  FREE: 'FREE',
  NOTK: 'NOTK',
  NVT: 'NVT',
  RESERVED: 'RESERVED',
  SEE_DESCRIPTION: 'SEE_DESCRIPTION',
  ON_REQUEST: 'ON_REQUEST',
};

export const ELECTRONICS_ATTRIBUTE_CONFIG_ID = 2435;
export const BOOKS_ATTRIBUTE_CONFIG_ID = 2436;

export const HOUSE_AD_CATEGORY_PARENT_ID = 1032;
export const HOUSE_NUMBER_OF_ROOMS_KEY = 'numberOfRooms';
export const HOUSE_NUMBER_OF_BEDROOMS_KEY = 'numberOfBedrooms';
export const HOUSE_SQUARE_METERS_KEY = 'livingArea';
export const HOUSE_TYPE_KEY = 'typeOfProperty';
export const HOUSE_ENERGY_LABEL_KEY = 'energyLabel';
export const HOUSE_REGION_KEY = 'region';
export const HOUSE_CITY_KEY = 'city';

export const CAR_VEHICLE_TYPE_KEY = 'vehicleType';
export const CAR_TRANSMISSION_KEY = 'transmission';
export const CAR_FUEL_TYPE_KEY = 'fuel';
export const CAR_MILEAGE_KEY = 'mileage';
export const CAR_COLOR_KEY = 'color';
export const CAR_HP_POWER_KEY = 'powerInHorsePower';
export const CAR_TOP_SPEED_KEY = 'topSpeed';
export const CAR_CYLINDER_CAPACITY_KEY = 'cylinderCapacity';
export const CAR_MIN_SPEED = 0;
export const CAR_BRAND_KEY = 'brand';
export const CAR_OPTIONS_KEY = 'options';
export const CAR_TRIM_KEY = 'trim';
export const CAR_FUEL_CONSUMPTION_KEY = 'fuelConsumption';
export const CAR_CONSTRUCTION_YEAR_KEY = 'constructionYear';
export const CAR_POWER_WHEEL_DRIVER_KEY = 'powerWheelDriver';
export const CAR_ACCELERATION_KEY = 'acceleration';
export const CAR_LAST_OWNER_TYPE_KEY = 'lastOwnerType';
export const CAR_IS_IMPORTED_KEY = 'isImported';
export const CAR_IS_TURBO_KEY = 'isTurbo';
export const CAR_SERVICE_HISTORY_KEY = 'serviceHistory';
export const CAR_NUMBER_OF_DOORS_KEY = 'numberOfDoors';
export const CAR_NUMBER_OF_SEATS_KEY = 'numberOfSeats';
export const CAR_INTERIOR_COLOR_KEY = 'interiorcolor';
export const CAR_BATTERY_CHARGE_TIME_KEY = 'batteryChargeTime';
export const CAR_BATTERY_FAST_CHARGE_TIME_KEY = 'batteryFastChargeTime';

export const MAIN_GROUP_NAME = 'Main';
export const DATA_TYPE_TIME = 'Timestamp';
export const DATA_TYPE_INTEGER = 'Integer';
export const DATA_TYPE_DOUBLE = 'Double';
export const NO_VALUE = 'Nee';
export const MINUTES_IN_HOUR = 60;

export const CAR_HP_UNIT_CODE = 'N12'; // https://schema.org/EngineSpecification, pk(paardenkracht) is metric horse power

/**
 * ad actual duration is 112. after an ad is placed it gets automatically extended on the 28th day for 3 times (28 first placed+ 28 *3 all extensions = 112)
 * We set the limit to 90 because we don't want to google crawling for too much time on VIP urls which wastes crawling resources that we'd rather use on other places like LRP
 */

export const JOB_AD_DURATION_DAYS = 90;
export const JOB_AD_EXPIRY_BUFFER_DAYS = 2;
export const JOB_AD_CATEGORY_PARENT_ID = 167;
export const JOB_EMPLOYMENT_TYPE_CONFIG_ID = 1352;
export const JOB_OTHER_TYPE_NL = 'Overige vormen';
export const JOB_OTHER_TYPE_FR = 'Autres formes';

export const META_DESCRIPTION_LENGTH = 150;

export const BANNER_VIP_ACTIONS = {
  SMB: '5',
} as const;

export const SELLER_TYPE = {
  CONSUMER: 'CONSUMER',
  TRADER: 'TRADER',
  UNKNOWN: 'UNKNOWN',
  ERROR: 'ERROR',
} as const;
