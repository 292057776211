import React, { useEffect, useRef, useState } from 'react';
import { SecondaryRoundIconButton } from '@hz-design-system/web-ui';

import { getThumbnailImageUrl } from '../../../../utils/getImageUrls';
import { TListingGallery } from 'types/TListing';

import Classes from './Thumbnails.scss';

type TThumbnailsProps = {
  imageUrls: TListingGallery['imageUrls'];
  imageSizes?: TListingGallery['media']['imageSizes'];
  showNavigation: boolean;
  activeImageIndex?: number;
  onChange: Function;
  maxVisibleImageCount: number;
};

const thumbnailItemWidth = 73;

const getTranslateXValue = (element) => {
  if (element) {
    return parseInt(element.style.transform.replace(/[^\d.]/g, ''), 10) || 0;
  }

  return 0;
};

const Thumbnails = ({
  imageUrls,
  imageSizes,
  showNavigation,
  onChange,
  maxVisibleImageCount,
  activeImageIndex = 0,
}: TThumbnailsProps) => {
  const [activeThumbnail, setActiveThumbnail] = useState(activeImageIndex);
  const [loadAllImages, setLoadAllImages] = useState(false);
  const thumbnailScrollElement = useRef<HTMLHeadingElement>(null);
  const thumbnailContainerElement = useRef<HTMLHeadingElement>(null);

  const onMouseOver = (thumbnailIndex) => {
    if (thumbnailIndex === activeThumbnail) {
      return;
    }
    setActiveThumbnail(thumbnailIndex);
    onChange(thumbnailIndex);
  };

  const showThumbnailInView = (index) => {
    if (thumbnailScrollElement?.current && thumbnailContainerElement?.current) {
      const containerWidth = thumbnailContainerElement.current.clientWidth;
      const leftPosition = getTranslateXValue(thumbnailScrollElement.current);

      const activeElementPosition = thumbnailItemWidth * index;

      if (containerWidth + leftPosition <= activeElementPosition) {
        setLoadAllImages(true);
        thumbnailScrollElement.current.style.transform = `translateX(-${activeElementPosition}px)`;
      }
    }
  };

  useEffect(() => {
    setActiveThumbnail(activeImageIndex);
    showThumbnailInView(activeImageIndex);
  }, [activeImageIndex]);

  const showNextItems = () => {
    setLoadAllImages(true);
    if (thumbnailScrollElement?.current && thumbnailContainerElement?.current) {
      const leftPosition = getTranslateXValue(thumbnailScrollElement.current);
      const elementCount = thumbnailContainerElement.current.clientWidth / thumbnailItemWidth;
      const leftAction = Math.floor(elementCount) * thumbnailItemWidth;

      const targetPosition = leftPosition + leftAction;
      const max = thumbnailScrollElement.current.clientWidth - thumbnailContainerElement.current.clientWidth;
      thumbnailScrollElement.current.style.transform = `translateX(${
        -targetPosition >= -max ? -targetPosition : -max
      }px)`;
    }
  };

  const showPrevItems = () => {
    if (thumbnailScrollElement?.current && thumbnailContainerElement?.current) {
      const leftPosition = getTranslateXValue(thumbnailScrollElement.current);
      const elementCount = thumbnailContainerElement.current.clientWidth / thumbnailItemWidth;
      const leftAction = Math.floor(elementCount) * thumbnailItemWidth;
      const targetPosition = leftPosition - leftAction;
      thumbnailScrollElement.current.style.transform = `translateX(${-targetPosition <= 0 ? -targetPosition : 0}px)`;
    }
  };

  return (
    <div className={Classes.root}>
      {showNavigation && (
        <SecondaryRoundIconButton
          aria-label="prev"
          name="arrow_left"
          size="small"
          onClick={showPrevItems}
          data-testid="thumbnails-arrow-icon"
        ></SecondaryRoundIconButton>
      )}

      <div ref={thumbnailContainerElement} className={Classes.cover}>
        <div ref={thumbnailScrollElement} className={Classes.scroll}>
          {imageUrls.map((imageUrl, index) => (
            <span
              onMouseOver={() => onMouseOver(index)}
              key={index}
              className={`${Classes.item} ${index === activeThumbnail ? Classes.active : ''}`}
              style={
                loadAllImages || index < maxVisibleImageCount
                  ? {
                      backgroundImage: `url("${getThumbnailImageUrl(imageUrl, imageSizes)}") `,
                    }
                  : {}
              }
            />
          ))}
        </div>
      </div>

      {showNavigation && (
        <SecondaryRoundIconButton
          aria-label="next"
          name="arrow_right"
          size="small"
          onClick={showNextItems}
          data-testid="thumbnails-arrow-icon"
        ></SecondaryRoundIconButton>
      )}
    </div>
  );
};

export default Thumbnails;
