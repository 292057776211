import React from 'react';

import AdsenseForSearch from './AdsenseForSearch';

import { TListingClient } from 'types/TListing';

type TAdsenseProps = {
  title: TListingClient['title'];
};

const Adsense = ({ title }: TAdsenseProps) => {
  return <AdsenseForSearch title={title} />;
};

export default Adsense;
