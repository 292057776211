import React, { useEffect, useState, useRef } from 'react';
import { trackGAEvent } from '../../../../utils/gaTracking';

import Classes from './BannerButton.scss';

type TBannerButtonProps = {
  bannerPositionId: string;
};

type TBannerButtonContentProps = {
  adHtml: string;
  adPosition: string;
};

const BannerButton = ({ bannerPositionId }: TBannerButtonProps) => {
  const [adContent, setAdContent] = useState<TBannerButtonContentProps>();
  const [isBrenger, setIsBrenger] = useState<boolean>(false);
  const elRef = useRef(null);

  useEffect(() => {
    // This code is maintained by B2C team
    // used only for showing premium-content banners
    // GAM injects a script in the DOM that dispatches this custom event gpt-loaded
    // TODO change this event name to something more descriptive like show-premium-content
    document.addEventListener('gpt-loaded', (event: Event) => {
      const detail = (event as CustomEvent).detail;
      if (`${detail.adPosition}-dt` === bannerPositionId) {
        setAdContent(detail);
        if (detail.adHtml.includes('brenger')) {
          trackGAEvent('BannerButtonLoaded', 'Brenger');
          setIsBrenger(true);
        }
        const bannerContainer = document.getElementById(bannerPositionId);
        if (bannerContainer) {
          bannerContainer.style.display = 'none';
        }
      }
    });
  }, [bannerPositionId]);

  const handleClick = () => {
    const el = elRef.current;
    if (el) {
      const clickEl = el as HTMLElement;
      const clickUrlEl = clickEl.querySelector('[data-content-dialog]');
      if (clickUrlEl) {
        const clickUrl = clickUrlEl.attributes['data-content-dialog'].value;
        if (isBrenger) {
          trackGAEvent('BannerButtonClick', 'Brenger');
        }
        return window.open(clickUrl, '_blank');
      }
    }
  };

  return (
    <>
      <div id={bannerPositionId} className={Classes.box} />
      {adContent && (
        <div
          ref={elRef}
          className={Classes.container}
          // We trust this html as it comes from internal system
          // For any questions slack channel #mp-display-troopers
          dangerouslySetInnerHTML={{ __html: adContent.adHtml }} // eslint-disable-line react/no-danger
          onClick={handleClick}
          aria-hidden={true}
        />
      )}
    </>
  );
};

export default BannerButton;
