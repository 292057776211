import React from 'react';
import { useI18nContext } from '@ecg-marktplaats/js-react-i18n';
import { TextLarge, TextRegular, AvatarImage, ListBasic, ListItemWithImage } from '@hz-design-system/web-ui';

import { TSalesRepresentative } from 'types/TSellerPublicProfile';

import Classes from './SellerInfo.scss';

type TSellerSalesRepresentativesProps = {
  salesRepresentatives?: TSalesRepresentative[];
};

const SellerSalesRepresentatives = ({ salesRepresentatives = [] }: TSellerSalesRepresentativesProps) => {
  const { t } = useI18nContext();
  if (!salesRepresentatives.length) {
    return null;
  }

  return (
    <div className={Classes.section}>
      <ListBasic className={Classes.representativeList} title={t('sellerInfo.representatives.header')}>
        {salesRepresentatives.map(({ name, title, photo }) => (
          <ListItemWithImage
            key={name}
            image={
              <AvatarImage
                src={photo || ''}
                alt={t('sellerInfo.representatives.imageAlt')}
                placeholderIconName="profile"
              />
            }
          >
            <TextLarge>{name}</TextLarge>
            <TextRegular>{title}</TextRegular>
          </ListItemWithImage>
        ))}
      </ListBasic>
    </div>
  );
};

export default SellerSalesRepresentatives;
