import { useI18nContext } from '@ecg-marktplaats/js-react-i18n';
import React, { useContext, useEffect } from 'react';

import { TListingClient } from 'types/TListing';
import { priceTypes } from '../../../../../../server/utils/constants';
import EnvironmentContext from '../../../../contexts/EnvironmentContext';
import useCesSurvey from '../../../../hooks/useCesSurvey';
import formatPrice from '../../../../utils/formatPrice';
import { storeToRecentlyViewedVips } from '../../../../utils/storeToRecentlyViewedVips';
import storeVipViewCount from '../../../../utils/storeVipViewCount';
import Gallery from '../Gallery/Gallery';
import BuyersProtectionModalLink from './BuyersProtection/ModalLink/ModalLink';
import CarUspBlocks from './CarUspBlocks';
import FavouriteButton from './FavouriteButton';
import Classes from './Listing.scss';
import ShippingInformation from './ShippingInformation';
import Stats from './Stats';
import RefurbishmentUspBlock from './RefurbishmentUspBlock';

type TListingProps = {
  listing: TListingClient;
};

const Listing = ({ listing }: TListingProps) => {
  const { t } = useI18nContext();
  const {
    userDetails: { isAdOwner = false } = {},
    buyerProtectionEnabled,
    shippingLabelsEnabled,
    isVerifiedRefurbishedAd,
  } = useContext(EnvironmentContext);
  const {
    itemId,
    isSaved,
    isSavingEnabled,
    gallery,
    title,
    traits = [],
    priceInfo,
    flags: { shippable, isAdmarkt, isLeaseCar, showLoanIndicators },
    uspInformation,
    isCarAd,
    carDetails,
    buyersProtectionAllowed,
    buyersProtectionModalImageUrl,
  } = listing;
  const showMicroTipText = traits.includes('MICROTIP');
  const showLargePhotos = traits.includes('EXTRA_LARGE_PHOTOS') && isCarAd;
  const isCurrency = [priceTypes.MIN_BID, priceTypes.FIXED].includes(priceInfo.priceType);
  const isPricePerMonth = isLeaseCar && isCurrency;
  const { loadSurvey } = useCesSurvey(isCarAd);

  useEffect(() => {
    window.addEventListener('asqSent', loadSurvey, false);
    return () => window.removeEventListener('asqSent', loadSurvey, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    storeToRecentlyViewedVips(t, listing);
    storeVipViewCount(listing.isCarAd);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`${Classes.root} ${showLargePhotos ? Classes.showLargePhotos : ''}`}>
      <Gallery gallery={gallery} title={title} showMicroTipText={showMicroTipText} showLargePhotos={showLargePhotos} />
      <header className={Classes.header}>
        {isSavingEnabled && !isAdOwner && <FavouriteButton isSaved={isSaved} itemId={itemId} />}
        <h1 className={Classes.title}>{listing.title}</h1>
      </header>
      <div className={Classes.informationContainer}>
        {isPricePerMonth && (
          <div className={Classes.price}>
            <span className={Classes.pricePerMonth}>{t('listing.leaseprice.prefix')}</span>
            <span>{` ${formatPrice(listing.priceInfo, t, isCarAd)} `}</span>
            <span className={Classes.pricePerMonth}>{` ${t('listing.leaseprice.suffix')}`}</span>
            {showLoanIndicators && <span>*</span>}
          </div>
        )}
        {!isPricePerMonth && <div className={Classes.price}>{formatPrice(listing.priceInfo, t, isCarAd)}</div>}
        {!isCarAd && (
          <ShippingInformation
            shippingInformation={listing.shippingInformation}
            shippable={shippable}
            shippingLabelsEnabled={shippingLabelsEnabled}
            isAdmarkt={isAdmarkt}
            isVerifiedRefurbishedAd={isVerifiedRefurbishedAd}
          />
        )}
        {!isCarAd && isVerifiedRefurbishedAd && <RefurbishmentUspBlock />}
        {buyersProtectionModalImageUrl && buyersProtectionAllowed && buyerProtectionEnabled && !isCarAd && (
          <BuyersProtectionModalLink imageUrl={buyersProtectionModalImageUrl} />
        )}
        {isCarAd && (
          <CarUspBlocks uspInformation={uspInformation} carDetails={carDetails} asHorizontalList={showLargePhotos} />
        )}
      </div>
      <Stats stats={listing.stats} withDivider={showLargePhotos} />
    </div>
  );
};

export default Listing;
