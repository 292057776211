import { bannerPositions } from '../../../constants/banners';

const queuedPositions: string[] = [];

const displayQueuedPosition = () => {
  if (queuedPositions.length) {
    // For refreshing or calling the positions another time please use refreshBids() function!
    // window.ecgJsBanners?.setupAdditionalBanners([bannerPositions[position].key]);
    window.ecgJsBanners?.setupAdditionalBanners(queuedPositions);
    queuedPositions.length = 0;
  }
};

const queuePosition = (position: string) => {
  // check if ecg-banner-lib is ready! If banner library is ready, it will be called the positions automatically
  if (window.ecgJsBanners?.setupAdditionalBanners) {
    // if banners in modal are already in display in first load,
    // setupAdditionalBanners function needs to be triggered here!
  } else {
    queuedPositions.push(bannerPositions[position].key);
  }
};

const displayAfterInteractionPositions: string[] = [];

const displayBannerAfterInteraction = (position: string) => {
  if (window.ecgJsBanners?.setupAdditionalBanners) {
    if (displayAfterInteractionPositions.includes(position)) {
      // refresh the banner if it was displayed earlier
      window.ecgJsBanners.refreshBids([bannerPositions[position].id]);
    } else {
      // display banner for the first time
      window.ecgJsBanners?.setupAdditionalBanners([position]);
      displayAfterInteractionPositions.push(position);
    }
  }
};

/*
 Refresh static banners on the page except the action banner
 This function should be fired by opening a modal/dialog window
 */
const refreshBannersInModalBackground = () => {
  // Ids of the banners that should be refreshed
  const refreshIds = Object.keys(bannerPositions)
    .filter((pos) => bannerPositions[pos].refreshable)
    .map((pos) => bannerPositions[pos].id);

  if (refreshIds.length && window.ecgJsBanners?.refreshBids) {
    window.ecgJsBanners.refreshBids(refreshIds);
  }
};

export { queuePosition, displayQueuedPosition, displayBannerAfterInteraction, refreshBannersInModalBackground };
