import React from 'react';

import BlockSkeleton from '../../../../components/Skeleton/BlockSkeleton';
import TextSkeleton from '../../../../components/Skeleton/TextSkeleton';

import Classes from './AdmarktSimilarItems.scss';

const ITEMS_COUNT = 5;

type TAdmarktSimilarItemsListSkeletonProps = {
  isHorizontal: boolean;
};

const AdmarktSimilarItemsListSkeleton = ({ isHorizontal }: TAdmarktSimilarItemsListSkeletonProps) => {
  if (isHorizontal) {
    return (
      <>
        {Array(ITEMS_COUNT)
          .fill(true)
          .map((_, index) => (
            <div className={`hz-Card ${Classes.item}`} key={index}>
              <article className="hz-Listing hz-Listing--vertical">
                <div className="hz-Image hz-Listing-image">
                  <BlockSkeleton withAnimation={true} />
                </div>
                <div className="hz-Block">
                  <div className="hz-Listing-title">
                    <TextSkeleton />
                  </div>
                  <div className="hz-Listing-price">
                    <TextSkeleton type="short" />
                  </div>
                </div>
              </article>
            </div>
          ))}
      </>
    );
  }

  return (
    <>
      {Array(ITEMS_COUNT)
        .fill(true)
        .map((_, index) => (
          <div className={`hz-Card ${Classes.item}`} key={index}>
            <div className={`hz-Image hz-Listing-image ${Classes.imageContainer}`}>
              <BlockSkeleton className={Classes.image} withAnimation={true} />
            </div>
            <div className={`hz-Block ${Classes.data}`}>
              <div className={`hz-Listing-title ${Classes.title}`}>
                <TextSkeleton />
              </div>
              <div className={`hz-Listing-price ${Classes.price}`}>
                <TextSkeleton type="short" />
              </div>
              <div className={Classes.location}>
                <TextSkeleton type="short" />
              </div>
            </div>
          </div>
        ))}
    </>
  );
};

export default AdmarktSimilarItemsListSkeleton;
