import React, { useState, useEffect } from 'react';
import { Icon, Link } from '@hz-design-system/web-ui';

import Classes from './ModalLink.scss';
import { trackGAEvent } from '../../../../../../utils/gaTracking';
import { GA_TRACK_EVENTS_ACTIONS, GA_TRACK_EVENTS_LABELS } from '../../../../../../utils/constants';
import { useI18nContext } from '@ecg-marktplaats/js-react-i18n';

let Modal;

type TProps = {
  imageUrl: string;
};

const BuyersProtectionModalLink = ({ imageUrl }: TProps) => {
  const { t } = useI18nContext();
  const [isOpen, setIsOpen] = useState(false);
  const [isModalLoaded, setIsModalLoaded] = useState(false);
  const [windowWidth, setWindowWidth] = useState(0);
  const [title, setTitle] = useState('buyerProtection.modal.title.desktop');
  const mobileBreakPoint = 480;

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);

    handleResize();

    if (windowWidth <= mobileBreakPoint) {
      setTitle('buyerProtection.modal.title.mobile');
    } else {
      setTitle('buyerProtection.modal.title.desktop');
    }

    return () => window.removeEventListener('resize', handleResize);
  }, [windowWidth]);

  const loadModalComponent = async () => {
    Modal = (await import(/* webpackChunkName: "BuyersProtectionModal" */ '../Modal/Modal')).default;
    setIsModalLoaded(true);
  };

  const handleClick = () => {
    trackGAEvent(GA_TRACK_EVENTS_ACTIONS.Click, GA_TRACK_EVENTS_LABELS.BuyerProtection, { category: 'VIP' });
    if (!isModalLoaded) {
      loadModalComponent();
    }
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Link onClick={handleClick} className={Classes.buyersProtectionLink} data-testid="buyerProtectionLink">
        <div>
          <Icon name="veilig-handelen" color="signal-link-default" size="m" />
        </div>
        <span className={Classes.label}>Kopersbescherming beschikbaar</span>
        <div>
          <Icon name="info" color="signal-link-default" size="m" />
        </div>
      </Link>
      {isModalLoaded && isOpen && <Modal toggle={handleClick} imageUrl={imageUrl} title={t(title)} />}
    </>
  );
};

export default BuyersProtectionModalLink;
