import React from 'react';
import { EnvironmentProvider } from '../../contexts/EnvironmentContext';
import Banners from './components/Banners/Banners';

type TBannersAppProps = {
  environment: any;
  position: string;
};

const BannersApp = ({ position, environment }: TBannersAppProps) => (
  <EnvironmentProvider value={environment}>
    <Banners position={position} />
  </EnvironmentProvider>
);
export default BannersApp;
