import React from 'react';
import { useI18nContext } from '@ecg-marktplaats/js-react-i18n';
import { BackgroundImage, Image } from '@hz-design-system/web-ui';

import Classes from './SellerInfo.scss';

type TSellerProfilePicturesProps = {
  showProfilePicture: boolean;
  profilePictures?: {
    backgroundPicture?: string;
    logoPicture?: string;
  };
  name: string;
};

const SellerProfilePictures = ({
  showProfilePicture,
  profilePictures: { backgroundPicture = '', logoPicture } = {},
  name,
}: TSellerProfilePicturesProps) => {
  const { t } = useI18nContext();
  if (!showProfilePicture || (!backgroundPicture && !logoPicture)) {
    return null;
  }

  return (
    <div className={Classes.profilePictures}>
      {backgroundPicture && (
        <BackgroundImage
          src={backgroundPicture}
          alt={`${t('sellerInfo.profilePictures.backgroundAltText.altText')} ${name}`}
          className={Classes.backgroundPicture}
        />
      )}
      {logoPicture && (
        <div className={Classes.logoPicture}>
          <Image
            src={logoPicture}
            alt={`${t('sellerInfo.profilePictures.logoAltText.altText')} ${name}`}
            style={{ backgroundImage: `url(${logoPicture})` }}
          />
        </div>
      )}
    </div>
  );
};

export default SellerProfilePictures;
