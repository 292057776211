import React from 'react';

import AdmarktSimilarItemsListSkeleton from './AdmarktSimilarItemsListSkeleton';
import AdmarktSimilarItem from './AdmarktSimilarItem';

import { TAdmarktItemClient } from 'types/TAdmarkt';

import Classes from './AdmarktSimilarItems.scss';

type TAdmarktSimilarItemsListProps = {
  isLoading: boolean;
  isHorizontal: boolean;
  items: TAdmarktItemClient[];
};

const AdmarktSimilarItemsList = ({ isLoading, items, isHorizontal }: TAdmarktSimilarItemsListProps) => (
  <div className={`${Classes.similarItemsExperiment}`}>
    {isLoading && <AdmarktSimilarItemsListSkeleton isHorizontal={true} />}
    {!isLoading &&
      items.map((item) => <AdmarktSimilarItem key={item.itemId} item={item} isHorizontal={isHorizontal} />)}
  </div>
);

export default AdmarktSimilarItemsList;
