import React from 'react';
import { Trans } from '@ecg-marktplaats/js-react-i18n';
import { Icon } from '@hz-design-system/web-ui';

import Classes from './NoImage.scss';

const NoImage = () => (
  <div className={Classes.root}>
    <Icon name="camera" />
    <Trans className={Classes.title} i18nKey="gallery.no-image" />
    <Trans className={Classes.description} i18nKey="gallery.no-image-tip" />
  </div>
);

export default NoImage;
