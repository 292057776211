import React from 'react';

import { EnvironmentProvider } from '../../contexts/EnvironmentContext';

import { TListing } from 'types/TListing';

import Report from './components/Report/Report';

type TReportAppProps = {
  appState: {
    listing: TListing;
  };
  environment: any;
};

const ReportApp = ({ appState, environment }: TReportAppProps) => {
  const {
    listing: { itemId },
  } = appState;
  return (
    <EnvironmentProvider value={environment}>
      <Report itemId={itemId} />
    </EnvironmentProvider>
  );
};

export default ReportApp;
