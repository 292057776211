import React, { useState } from 'react';
import { Icon, Link } from '@hz-design-system/web-ui';
import { useI18nContext } from '@ecg-marktplaats/js-react-i18n';

import getFormattedLocation from '../../../../utils/getFormattedLocation';

import { TListingSellerInfo } from 'types/TListing';

import Classes from './SellerInfo.scss';

let MapModal;

type TSellerLocationProps = {
  location: TListingSellerInfo['location'];
  showMap: TListingSellerInfo['showMap'];
};

const SellerLocation = ({ location, showMap }: TSellerLocationProps) => {
  const { t } = useI18nContext();
  const [isMapOpen, setIsMapOpen] = useState(false);
  const [isMapModalLoaded, setIsMapModalLoaded] = useState(false);
  const formattedLocation = getFormattedLocation(location, t);

  const loadMapComponent = async () => {
    try {
      MapModal = (await import(/* webpackChunkName: "gmapModal" */ './MapModal')).default;
      setIsMapModalLoaded(true);
    } catch (err) {}
  };

  const handleOpenMap = async () => {
    if (!isMapModalLoaded) {
      await loadMapComponent();
    }

    setIsMapOpen(true);
  };

  const handleCloseMap = () => setIsMapOpen(false);

  // TODO: why? /* && location.isOnCountryLevel*/
  if (showMap && location.latitude && location.longitude) {
    return (
      <>
        {isMapModalLoaded && isMapOpen && (
          <MapModal title={t('sellerInfo.map.title')} onClose={handleCloseMap} location={location} />
        )}
        <div className={Classes.rowWithIcon}>
          <Icon name="location" color="signal-link-default" className={Classes.icon} />
          <Link onClick={handleOpenMap}>{formattedLocation}</Link>
        </div>
      </>
    );
  }

  return (
    <div className={Classes.rowWithIcon}>
      <Icon name="location" color="text-secondary" className={Classes.icon} />
      <span>{formattedLocation}</span>
    </div>
  );
};

export default SellerLocation;
