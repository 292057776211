import React, { useState, useContext } from 'react';
import { TertiaryIconButton } from '@hz-design-system/web-ui';
import EnvironmentContext from '../../../../contexts/EnvironmentContext';
import { BANNER_POSITION, bannerPositions } from '../../constants/banners';

import Classes from './BannerTop.scss';

const BannerTop = () => {
  const { isMobileDevice, isCarAd } = useContext(EnvironmentContext);
  const [showBanner, setShowBanner] = useState(true);

  const handleClick = () => {
    setShowBanner(false);
  };

  if (!showBanner) {
    return null;
  }
  const isBannerHidden = !isCarAd && isMobileDevice;

  return (
    <div className={!isBannerHidden ? Classes.root : Classes.rootExperiment}>
      <TertiaryIconButton className={Classes.buttonClose} name="close-small" aria-label="close" onClick={handleClick} />

      <div id={bannerPositions[BANNER_POSITION.STATIC.TOP].id} />
    </div>
  );
};

export default BannerTop;
