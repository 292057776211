export const BANNER_POSITION = {
  STATIC: {
    TOP: 'vip_top',
    RIGHT: 'vip_right',
    RIGHT1: 'vip_right1',
    LEFT: 'vip_left',
    MID: 'vip_mid',
    BOTTOM: 'vip_bot',
    BUTTON1: 'vip_btn1',
    BUTTON2: 'vip_btn2',
    ACTION: 'vip_action',
  },
  DYNAMIC: {
    IMAGE: 'vip_img_bot',
    MAP: 'vip_map_top',
    DEALER_TOP: 'vip_dealer_top',
  },
};

export const displayCookies = {
  ADOBE_IDENTIFIER: 'aam_uuid',
  RSI_SEGMENTS: 'rsi_segs',
};

// Banners configuration file with predefined variables that can't be overridden:
// https://github.mpi-internal.com/ecg-marktplaats/ecg-js-banners/blob/master/src/requestAds/bannerConfig.js
export const bannerPositions = {
  [BANNER_POSITION.STATIC.TOP]: {
    containerId: 'top-banner-root', // can be changed by us
    key: BANNER_POSITION.STATIC.TOP, // to trigger setupAdditionalBanners function
    id: 'banner-viptop-dt',
    hideEl: 'vip-banner-top-sticky-container', // should be the same as in the ecg-js-banner library
    refreshable: true,
  },
  [BANNER_POSITION.STATIC.RIGHT]: {
    containerId: 'right-banner-root',
    key: BANNER_POSITION.STATIC.RIGHT,
    hideEl: '#right-banner-root', // Hide the container that has 250 min height (min-height is to fix CLS issue)
    id: 'banner-mr-dt',
    refreshable: true,
  },
  [BANNER_POSITION.STATIC.RIGHT1]: {
    containerId: 'outside-banner-right-root',
    key: BANNER_POSITION.STATIC.RIGHT1,
    id: 'banner-sticky-right',
    refreshable: true,
  },
  [BANNER_POSITION.STATIC.LEFT]: {
    containerId: 'outside-banner-left-root',
    key: BANNER_POSITION.STATIC.LEFT,
    id: 'banner-vipleft-dt',
    refreshable: true,
  },
  [BANNER_POSITION.STATIC.MID]: {
    containerId: 'mid-banner-root',
    key: BANNER_POSITION.STATIC.LEFT,
    id: 'banner-vipmid-dt',
    refreshable: false,
  },
  [BANNER_POSITION.STATIC.BOTTOM]: {
    containerId: 'bottom-banner-root',
    key: BANNER_POSITION.STATIC.BOTTOM,
    id: 'banner-vipbottom-dt',
    refreshable: false,
  },
  [BANNER_POSITION.STATIC.ACTION]: {
    containerId: 'action-banner-root',
    key: BANNER_POSITION.STATIC.ACTION,
    id: 'banner-vipaction-dt',
    refreshable: false,
  },
  [BANNER_POSITION.DYNAMIC.IMAGE]: {
    containerId: 'image-popup-banner-root',
    key: BANNER_POSITION.DYNAMIC.IMAGE,
    id: 'vip-image-popup-banner-dt',
    refreshable: false,
  },
  [BANNER_POSITION.DYNAMIC.MAP]: {
    containerId: 'map-popup-banner-root',
    key: BANNER_POSITION.DYNAMIC.MAP,
    id: 'vip-map-banner-dt',
    refreshable: false,
  },
  [BANNER_POSITION.DYNAMIC.DEALER_TOP]: {
    containerId: 'top-banner-root',
    key: BANNER_POSITION.DYNAMIC.DEALER_TOP,
    id: 'banner-viptop-dealer',
    refreshable: false,
  },
  [BANNER_POSITION.STATIC.BUTTON1]: {
    containerId: 'btn1-banner-root',
    key: BANNER_POSITION.STATIC.BUTTON1,
    id: 'premium-content-left-dt',
    refreshable: false,
  },
  [BANNER_POSITION.STATIC.BUTTON2]: {
    containerId: 'btn2-banner-root',
    key: BANNER_POSITION.STATIC.BUTTON2,
    id: 'premium-content-right-dt',
    refreshable: false,
  },
};
