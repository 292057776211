import React, { useEffect, useState } from 'react';

import DescriptionButton from './components/DescriptionButton';

const DESCRIPTION_CONTENT_HEIGHT = 180;

const DescriptionButtonApp = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (!window.ResizeObserver) {
      return;
    }

    const resizeObserver = new window.ResizeObserver((entries) => {
      window.requestAnimationFrame(() => {
        if (!Array.isArray(entries) || !entries.length) {
          return;
        }
        // this array should contain only one element
        entries.forEach((entry) => {
          if (entry.contentRect.height >= DESCRIPTION_CONTENT_HEIGHT) {
            setIsVisible(true);
          } else if (isVisible) {
            setIsVisible(false);
          }
        });
      });
    });

    const element = document.querySelector('[data-collapsable="description"]');
    if (element) {
      resizeObserver.observe(element);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [isVisible]);

  if (!isVisible) {
    return null;
  }

  return <DescriptionButton />;
};

export default DescriptionButtonApp;
