import React, { useEffect, useState, useContext } from 'react';
import { Link, StarRating } from '@hz-design-system/web-ui';
import { Trans, useI18nContext } from '@ecg-marktplaats/js-react-i18n';

import { trackGAEvent } from '../../../../utils/gaTracking';
import { GA_PARAMETER, GA_TRACK_EVENTS_ACTIONS } from '../../../../utils/constants';
import createCustomDimension from '../../../../utils/createCustomDimension';
import EnvironmentContext from '../../../../contexts/EnvironmentContext';

import { TSellerProfileClient } from 'types/TSellerProfile';

import Classes from './SellerInfo.scss';

let SellerReviewDialog;

type TSellerReviewsProps = {
  averageScore: TSellerProfileClient['averageScore'];
  numberOfReviews: TSellerProfileClient['numberOfReviews'];
};

const SellerReviews = ({ averageScore, numberOfReviews }: TSellerReviewsProps) => {
  const { t } = useI18nContext();
  const { links: { seller: sellerLinks = {} } = {}, hasLicensePlate } = useContext(EnvironmentContext);
  const [isOpen, setIsOpen] = useState(false);
  const [isSellerReviewDialogLoaded, setSellerReviewDialogLoaded] = useState(false);

  const minScore = 0;
  const maxScore = 5;
  const ratingIsValid = averageScore && averageScore >= minScore && averageScore <= maxScore;

  useEffect(() => {
    if (numberOfReviews || averageScore) {
      trackGAEvent(GA_TRACK_EVENTS_ACTIONS.SellerRatingsAvailable, '', {
        ...createCustomDimension({
          dimension: GA_PARAMETER.AD_HAS_LICENSE_PLATE,
          payload: hasLicensePlate,
        }),
      });
    }
  }, [numberOfReviews, averageScore, hasLicensePlate]);

  if (!numberOfReviews || !averageScore) {
    return null;
  }

  const loadSellerReviewDialog = async () => {
    if (!isSellerReviewDialogLoaded) {
      try {
        SellerReviewDialog = (await import(/* webpackChunkName: "SellerReviewDialog" */ './SellerReviewDialog'))
          .default;
        setSellerReviewDialogLoaded(true);
      } catch (err) {}
    }
  };

  const handleClick = (event) => {
    event.preventDefault();
    trackGAEvent(GA_TRACK_EVENTS_ACTIONS.SellerRatingsClicked, `${averageScore}`);
    loadSellerReviewDialog();
    setIsOpen(true);
  };
  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <div className={Classes.row}>
      {ratingIsValid && <StarRating rating={averageScore} label="" size="m" />}
      {
        <Link href={sellerLinks.reviews} onClick={handleClick}>
          <Trans tagName="span" i18nKey="sellerInfo.review" count={numberOfReviews} />
        </Link>
      }
      {isSellerReviewDialogLoaded && isOpen && (
        <SellerReviewDialog title={t('sellerInfo.reviewDialog')} src={sellerLinks.reviews} onClose={handleClose} />
      )}
    </div>
  );
};

export default SellerReviews;
