import React from 'react';
import { Icon, MaskedLink } from '@hz-design-system/web-ui';

import { trackGAEvent } from '../../../../utils/gaTracking';
import { TListingClient, TListingSellerInfo } from 'types/TListing';

import Classes from './SellerInfo.scss';
import { GA_PARAMETER, GA_TRACK_EVENTS_ACTIONS } from '../../../../utils/constants';
import createCustomDimension from '../../../../utils/createCustomDimension';

type TSellerWebsiteLinkProps = {
  url: TListingSellerInfo['sellerWebsiteUrl'];
  text: TListingSellerInfo['sellerWebsiteDisplayUrl'];
  sellerType: TListingClient['seller']['sellerType'];
  hasLicensePlate?: string;
};

const SellerWebsiteLink = ({ url, text, sellerType, hasLicensePlate }: TSellerWebsiteLinkProps) => {
  if (!url || !text) {
    return null;
  }

  const handleClick = () => {
    trackGAEvent(GA_TRACK_EVENTS_ACTIONS.R2SExternalBegin, '', {
      ...createCustomDimension({ dimension: GA_PARAMETER.AD_SELLER_TYPE, payload: sellerType }),
      ...createCustomDimension({ dimension: GA_PARAMETER.AD_HAS_LICENSE_PLATE, payload: hasLicensePlate }),
    });
  };

  return (
    <div className={`${Classes.rowWithIcon} ${Classes.websiteLink}`}>
      <MaskedLink href={url} onClick={handleClick} target="_blank" rel="nofollow">
        <Icon name="website" color="signal-link-default" className={Classes.icon} />
        {text}
      </MaskedLink>
    </div>
  );
};

export default SellerWebsiteLink;
