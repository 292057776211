import React from 'react';
import { Image } from '@hz-design-system/web-ui';

import Classes from './HeroImage.scss';

type THeroImageProps = {
  imageUrl: string;
  alt: string;
  onClickHandler: (index: any) => void;
};

// todo: add proper a11y attribute
/* eslint-disable jsx-a11y/click-events-have-key-events */
const HeroImage = ({ imageUrl, alt, onClickHandler }: THeroImageProps) => (
  <div className={Classes.root} onClick={onClickHandler}>
    <Image className={Classes.image} src={imageUrl} fetchpriority="high" alt={alt} title={alt} />
  </div>
);

export default HeroImage;
