import { useEffect } from 'react';
import enrichWithAdditionalMetrics from './enrichWithAdditionalMetrics';

const ATTRIBUTES = {
  TRACK_EVENT: 'data-ga-track-event',
};

/**
 * Tracks event in GA
 */
const trackGAEvent = (eventAction: string, eventLabel: string = '', options?: Record<string, string | number> | '') => {
  if (eventAction) {
    window.ecGa('trackEvent', enrichWithAdditionalMetrics({ eventAction, eventLabel, ...(options || {}) }));
  }
};

const handleClickEvent = (event) => {
  const { gaTrackEvent } = (event.currentTarget as HTMLElement)?.dataset;

  if (gaTrackEvent) {
    // options is JSON.stringified object
    const [eventAction, eventLabel, options] = gaTrackEvent.split(',').map((value) => decodeURIComponent(value));

    if (options) {
      try {
        const parsedOptions = JSON.parse(options);
        trackGAEvent(eventAction, eventLabel, parsedOptions);
        return;
      } catch (_) {
        // do nothing
      }
    }

    trackGAEvent(eventAction, eventLabel);
  }
};

/**
 * Attaches `onClick` event handler to track click on *SSR-only* elements in GA
 * Used for links in app-shell that are not rehydrated client-side.
 */
const useGATrackingForSSR = function () {
  useEffect(() => {
    document.querySelectorAll(`[${[ATTRIBUTES.TRACK_EVENT]}]`).forEach((item) => {
      item.addEventListener('click', handleClickEvent, false);
    });
  }, []); // `[]` to run effect only once
};

/**
 * Returns attribute to enable tracking the click in GA
 * Apply for links in app-shell that are not rehydrated client-side
 *
 * options must be JSON.stringified object
 */
const getGATrackingAttrForSSR = (action: string, label: string, options?: string): object => ({
  [ATTRIBUTES.TRACK_EVENT]: `${encodeURIComponent(action)},${encodeURIComponent(label)}${options ? `,${options}` : ''}`,
});

export { getGATrackingAttrForSSR, trackGAEvent, useGATrackingForSSR };
