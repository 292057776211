import { currency as currencyFormatter } from '@ecg-marktplaats/aurora-js-formatters';
import { Asset, Icon, NeutralBadge, SuccessBadge } from '@hz-design-system/web-ui';
import React, { useContext } from 'react';
import { useI18nContext } from '@ecg-marktplaats/js-react-i18n';
import { v4 as uuidv4 } from 'uuid';
import {
  TAugmentedLabel,
  TCarrierId,
  TSelfShippingLabel,
  TLabel,
} from '@ecg-marktplaats/bff-buyers-domain/dist/node/shipping/types';

import { TListingClient } from 'types/TListing';
import ShippingModalLink from './Shipping/ModalLink/ModalLink';
import Classes from './ShippingInformation.scss';

import { DELIVERY_TYPE } from '../../../../utils/constants';
import EnvironmentContext from '../../../../contexts/EnvironmentContext';

type THardcodedCarriers = TCarrierId | 'brenger';

type TShippingInformationProps = {
  shippingInformation: TListingClient['shippingInformation'];
  shippable: boolean;
  shippingLabelsEnabled: boolean;
  isAdmarkt: boolean;
  isVerifiedRefurbishedAd?: boolean;
};

type TExtendedLabelsProps = {
  carrierId: THardcodedCarriers | null;
  labels: TLabel[];
  promoText?: string;
  isLink: boolean;
};

const ShippingInformation = ({
  shippingInformation,
  shippable,
  shippingLabelsEnabled,
  isAdmarkt,
  isVerifiedRefurbishedAd = false,
}: TShippingInformationProps) => {
  const { t } = useI18nContext();
  const { deliveryType, shippingCostCents, augmentedLabels } = shippingInformation;
  const minPriceCentsDhl = 399;
  const formattedMinCostDhl = currencyFormatter(minPriceCentsDhl, true);

  const {
    experiments: { isExtendedShippingLabelsDisabledForWeb, isExtendedShippingLabelsDisabled },
  } = useContext(EnvironmentContext);
  //@ts-expect-error
  const isBrengerIncluded: TExtendedLabelsProps[] = augmentedLabels?.find((item) => item.carrierId === 'brenger');

  const cost = shippingCostCents ? currencyFormatter(shippingCostCents, true) : '';
  const listingHasShipping =
    deliveryType?.attributeValueKey === DELIVERY_TYPE.SHIP ||
    deliveryType?.attributeValueKey === DELIVERY_TYPE.PICK_UP_OR_SHIP;

  //@ts-expect-error
  const brengerAugmentedLabel: TExtendedLabelsProps[] = augmentedLabels?.filter((item) => item.carrierId === 'brenger');

  const filteredAugmentedLabels = !listingHasShipping && isBrengerIncluded && brengerAugmentedLabel?.length > 0;
  // do not render an empty block if nothing to show
  if (!deliveryType && !cost && !shippable) {
    return null;
  }
  const containerClassname = (carrierId) => {
    if (carrierId && carrierId !== 'brenger') {
      return '';
    } else if (carrierId && carrierId === 'brenger') {
      return Classes.brenger;
    } else {
      return Classes.baseLine;
    }
  };
  const isCostCheaper = shippingCostCents && shippingCostCents < minPriceCentsDhl;

  const NonClickableShippingComponent = (
    <div className={`${Classes.logoAndInfo} ${Classes.flexStart}`}>
      {cost && (
        <>
          <Icon name="truck" />
          <span>{t('shipping.costInfo', { cost })}</span>
        </>
      )}
    </div>
  );

  const ExperimentalShippingComponent = (
    <>
      {cost && isCostCheaper && NonClickableShippingComponent}
      {!isCostCheaper && <ShippingModalLink formattedMinCost={formattedMinCostDhl} />}
    </>
  );

  const ExtendedLabelsComponent = ({ carrierId, labels, promoText, isLink }: TExtendedLabelsProps) => (
    <div className={`${Classes.logoAndInfo} ${containerClassname(carrierId)}`}>
      <div className={Classes.logo}>
        {carrierId ? (
          <Asset.Square name={carrierId !== 'brenger' ? `logo-${carrierId}` : `icon-logo-brenger`} size="m" />
        ) : (
          <Icon name="truck" />
        )}
      </div>
      <div className={Classes.info}>
        {labels.reduce((result, { label, price }: TLabel) => {
          const key = uuidv4();
          const labelString = `${label} `;
          const priceString = promoText || !isLink ? `<strong>${price}</strong> ` : `${price} `;
          const resultString = labelString + priceString;
          return [
            ...result,
            <span key={key}>
              {/* eslint-disable react/no-danger */}
              <span dangerouslySetInnerHTML={{ __html: resultString }}></span>
              {promoText && <SuccessBadge>{promoText}</SuccessBadge>}
            </span>,
          ];
        }, [] as React.ReactNode[])}
      </div>
    </div>
  );

  const showCorrectShippingComponent = () => {
    if (listingHasShipping && shippingLabelsEnabled && !isAdmarkt && augmentedLabels?.length !== 0) {
      if (!isExtendedShippingLabelsDisabled && !isExtendedShippingLabelsDisabledForWeb) {
        return augmentedLabels?.map(
          ({ shouldShowMoreInfo, carrierId, labels, promoText }: TAugmentedLabel | TSelfShippingLabel) => {
            const key = uuidv4();
            if (shouldShowMoreInfo) {
              return (
                <ShippingModalLink key={key}>
                  <ExtendedLabelsComponent carrierId={carrierId} labels={labels} promoText={promoText} isLink={true} />
                </ShippingModalLink>
              );
            }

            return <ExtendedLabelsComponent carrierId={carrierId} labels={labels} key={key} isLink={false} />;
          },
        );
      }

      return ExperimentalShippingComponent;
    } else if (
      filteredAugmentedLabels &&
      !isExtendedShippingLabelsDisabled &&
      !isExtendedShippingLabelsDisabledForWeb
    ) {
      const key = uuidv4();
      return (
        <ExtendedLabelsComponent
          carrierId={brengerAugmentedLabel[0].carrierId}
          labels={brengerAugmentedLabel[0].labels}
          key={key}
          isLink={false}
        />
      );
    } else {
      return NonClickableShippingComponent;
    }
  };

  return (
    <div className={Classes.root}>
      {deliveryType && (
        <NeutralBadge className={Classes.deliveryLabel}>{deliveryType.attributeValueLabel}</NeutralBadge>
      )}
      {!isVerifiedRefurbishedAd && <div className={Classes.shipping}>{showCorrectShippingComponent()}</div>}
    </div>
  );
};

export default ShippingInformation;
