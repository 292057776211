// Runtime webpack config
import '../../other/WebpackConfig';

import '../../../scss/vip.twh.nlbe.scss';

// i18n
import i18n from '../../i18n/nl-BE';

import vip from './vip';

vip(i18n);
