export const GA_TRACK_EVENTS_ACTIONS = {
  R2SEmailBegin: 'R2SEmailBegin',
  R2SEmailBeginFromURL: 'R2SEmailBeginFromURL',
  R2SEmailInitiated: 'R2SEmailInitiated',
  R2SEmailModalShown: 'R2SEmailModalShown',
  R2SEmailRedirectToASQ: 'R2SEmailRedirectToASQ',
  R2SEmailAttempt: 'R2SEmailAttempt',
  R2SEmailSuccess: 'R2SEmailSuccess',
  R2SEmailFailed: 'R2SEmailFailed',
  R2SEmailCancel: 'R2SEmailCancel',
  R2SBidSuccess: 'R2SBidSuccess',
  R2SPhoneBegin: 'R2SPhoneBegin',
  R2SSMSBegin: 'R2SSMSBegin',
  R2SExternalBegin: 'R2SExternalBegin',
  R2SBINBegin: 'R2SBINBegin',
  PostAdPaidSuccess: 'PostAdPaidSuccess',
  FeatureAdSuccess: 'FeatureAdSuccess',
  PostAdFreeSuccess: 'PostAdFreeSuccess',
  R2SPhoneClicked: 'R2SPhoneClicked',
  CallTrackingPhoneNumber: 'getCallTrackingPhoneNumber',
  AsqFields: 'ASQ_FIELDS',
  AsqCancelled: 'asq_cancelled',
  AsqSendForm: 'asq_send_form',
  AsqSelectedCan: 'asq_selected_can',
  AsqToggledTradeIn: 'asq_toggled_trade_in',
  ImageUpload: 'image_upload',
  SellerRatingsClicked: 'SellerRatingsClicked',
  SellerRatingsAvailable: 'SellerRatingsAvailable',
  OKNBannerClick: 'OKNBannerClick',
  OKNModalYes: 'OKNModalYes',
  OKNModalNo: 'OKNModalNo',
  Click: 'Click',
  ShippingAvailable: 'ShippingAvailable',
  BuyFromPartner: 'BuyFromPartner',
};

export const GA_TRACK_EVENTS_LABELS = {
  BuyerProtection: 'BP_Info',
  Shipping: 'Shipping_Info',
  ShippingAllPrices: 'Shipping_Bottom_Sheet',
};

export const GAAsqFormStatus = {
  changes: 'changes',
  noChanges: 'no_changes',
} as const;

export const GAImageUploadStatusAction = {
  added: 'added',
  removed: 'removed',
  uploadFailed: 'upload_failed',
  uploadSizeLimitExceeded: 'max_size_exceeded',
} as const;

export const GATradeInStatus = {
  enabled: 'enabled',
  disabled: 'disabled',
  unavailable: 'unavailable',
} as const;

export const GAAsqFieldStatus = {
  empty: 'empty',
  prefilled: 'prefilled',
  filled: 'filled',
} as const;

export const SELLER_TYPE = {
  CONSUMER: 'CONSUMER',
  TRADER: 'TRADER',
  UNKNOWN: 'UNKNOWN',
  ERROR: 'ERROR',
} as const;

export const GA_PARAMETER = {
  AD_SELLER_TYPE: 'ad_seller_type',
  AD_HAS_LICENSE_PLATE: 'license_plate',
  BUYER_PROTECTION: 'buyerProtection',
  HAS_SHIPPING: 'shipping_method',
  SHIPPING_COST: 'shipping_price',
  ASKING_PRICE: 'ad_price',
  AD_TYPE: 'ad_type',
  SELLER_ACCOUNT_ID: 'L2_location_ID',
  BUY_IT_NOW: 'BIN_Status',
  ASQ_FORM_ATTRIBUTES: 'asq_form_attributes',
};

export const DELIVERY_TYPE = {
  PICK_UP: 'Ophalen',
  SHIP: 'Verzenden',
  PICK_UP_OR_SHIP: 'Ophalen of Verzenden',
};

export const DELIVERY_METHOD = {
  PICK_UP: 'PICK_UP',
  DELIVERY: 'DELIVERY',
};

export const ADSENSE_EVENTS = {
  ADSENSE_FOR_SHOPPING_REQUESTED: 'adsenseForShoppingRequested',
  ADSENSE_FOR_SEARCH_REQUESTED: 'adsenseForSearchRequested',
  ADSENSE_FOR_SHOPPING_LOADED: 'adsenseForShoppingLoaded',
  ADSENSE_FOR_SEARCH_LOADED: 'adsenseForSearchLoaded',
};

export const AD_TYPE = {
  VIA_PARTNER: 'ViaPartner',
  ADMARKT: 'Admarkt',
  REGULAR_PAID: 'RegularPaid',
  REGULAR_FREE: 'RegularFree',
} as const;
