import React from 'react';
import Classes from './RefurbishmentUspBlock.scss';
import { Icon } from '@hz-design-system/web-ui';

const Benefit = ({ iconName, label }: { iconName: string; label: string }) => (
  <div className={Classes.benefit}>
    <div className={Classes.iconWrapper}>
      <Icon className={Classes.icon} name={iconName} color="signal-success-default" size="m" />
    </div>
    <div className={Classes.label}>{label}</div>
  </div>
);

const benefits = [
  {
    iconName: 'warranty',
    label: '2 jaar garantie',
  },
  {
    iconName: 'truck',
    label: 'Gratis verzending en retour',
  },
  {
    iconName: 'medal',
    label: 'Duurzame kwaliteit',
  },
  {
    iconName: 'charity',
    label: 'Geverifieerd refurbished',
  },
];

const RefurbishmentUspBlock = () => {
  return (
    <div className={Classes.root}>
      {benefits.map(({ iconName, label }) => {
        return <Benefit key={`${iconName}-${label}`} iconName={iconName} label={label} />;
      })}
    </div>
  );
};

export default RefurbishmentUspBlock;
