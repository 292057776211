import { TFavouriteListingClient } from 'types/TFavouriteListing';
import fetchAPI from '../../../utils/fetchAPI';

type TParams = {
  itemId: string;
  favourite: boolean;
  xsrfToken: string;
};

export default async function toggleFavouriteListing({
  itemId,
  favourite,
  xsrfToken,
}: TParams): Promise<TFavouriteListingClient> {
  try {
    return await fetchAPI({
      method: 'POST',
      url: '/v/api/favourite-listing',
      xsrfToken,
      payload: { itemId, favourite },
    });
  } catch (e) {
    return {
      // Return the previous state if request failed
      isFavourite: !favourite,
    };
  }
}
