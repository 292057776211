import React from 'react';
import { EnvironmentProvider } from '../../contexts/EnvironmentContext';

import InspectionService from './components/InspectionService';

type TInspectionServiceAppProps = {
  environment: any;
};

const InspectionServiceApp = ({ environment }: TInspectionServiceAppProps) => {
  return (
    <EnvironmentProvider value={environment}>
      <InspectionService />
    </EnvironmentProvider>
  );
};

export default InspectionServiceApp;
