import React from 'react';
import { Trans, useI18nContext } from '@ecg-marktplaats/js-react-i18n';
import { Asset, IconCompound } from '@hz-design-system/web-ui';

import { TSellerProfileClient } from 'types/TSellerProfile';

import Classes from './SellerInfo.scss';

type TSellerTrustIndicatorsProps = {
  verifications: TSellerProfileClient;
  shouldShowSmbVerified: boolean;
};

const SellerTrustIndicators = ({
  verifications: { bankAccount, identification, paymentMethod },
  shouldShowSmbVerified,
}: TSellerTrustIndicatorsProps) => {
  const { t } = useI18nContext();

  if (!bankAccount && !identification) {
    return null;
  }

  return (
    <div className={Classes.section}>
      {shouldShowSmbVerified && (
        <div className={`${Classes.row} ${Classes.rowTitle}`}>
          <Trans tagName="strong" i18nKey="sellerInfo.smbVerifiedSeller" />
        </div>
      )}
      {bankAccount && (
        <div className={Classes.rowWithIcon}>
          <IconCompound className={Classes.icon} name="credit-card" color="text-secondary" />
          <Trans tagName="span" i18nKey="sellerInfo.trustIndicators.bankAccount" />
        </div>
      )}
      {identification && (
        <div className={Classes.rowWithIcon}>
          <IconCompound className={Classes.icon} name="id" color="text-secondary" />
          <Trans tagName="span" i18nKey="sellerInfo.trustIndicators.identification" />
        </div>
      )}
      {bankAccount && paymentMethod && (
        <div className={Classes.rowWithIcon}>
          <Asset.Square className={Classes.icon} name={`icon-logo-${paymentMethod.name}`} />
          <Trans
            tagName="span"
            i18nKey="sellerInfo.trustIndicators.paymentMethod"
            paymentMethod={t(`sellerInfo.trustIndicators.paymentMethodName.${paymentMethod.name}`)}
          />
        </div>
      )}
    </div>
  );
};

export default SellerTrustIndicators;
