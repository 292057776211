import React from 'react';
import { Trans } from '@ecg-marktplaats/js-react-i18n';
import { TSellerType } from 'types/TListing';

import Classes from './SellerIdentity.scss';
import InfoClasses from './SellerInfo.scss';

type TSellerIdentityProps = {
  sellerType: TSellerType;
};

const SellerIdentity = ({ sellerType }: TSellerIdentityProps) => {
  switch (sellerType) {
    case 'CONSUMER': {
      return (
        <div className={InfoClasses.row}>
          <Trans tagName="p" i18nKey="sellerInfo.identity.consumer" className={Classes.message} />
        </div>
      );
    }
    case 'TRADER': {
      return (
        <div className={InfoClasses.row}>
          <Trans tagName="p" i18nKey="sellerInfo.identity.trader" className={Classes.badge} />
        </div>
      );
    }
    case 'UNKNOWN': {
      return (
        <div className={InfoClasses.row}>
          <Trans tagName="p" i18nKey="sellerInfo.identity.unknown" className={Classes.message} />
        </div>
      );
    }
    case 'ERROR':
    default: {
      return null;
    }
  }
};

export default SellerIdentity;
