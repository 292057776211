import React from 'react';
import { useGATrackingForSSR } from '../../utils/gaTracking';
import { useOnClickActionForSSR } from '../../utils/onClickActionForSSR';

import { EnvironmentProvider } from '../../contexts/EnvironmentContext';
import Listing from './components/Listing/Listing';

import { TListingClient } from 'types/TListing';

type TListingAppProps = {
  appState: {
    listing: TListingClient;
  };
  environment: any;
};

const ListingApp = ({ appState, environment }: TListingAppProps) => {
  useGATrackingForSSR();
  useOnClickActionForSSR();

  const { listing } = appState;

  return (
    <EnvironmentProvider value={environment}>
      <Listing listing={listing} />
    </EnvironmentProvider>
  );
};

export default ListingApp;
