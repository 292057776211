import React from 'react';
import { MaskedLink, Link, VerticalListing } from '@hz-design-system/web-ui';
import { useI18nContext } from '@ecg-marktplaats/js-react-i18n';

import formatPrice from '../../../../utils/formatPrice';
import { getLargeImageUrl } from '../../../../utils/getImageUrls';

import { TAdmarktItem } from 'types/TAdmarkt';
import Classes from './AdmarktSimilarItems.scss';

type TAdmarktSimilarItemProps = {
  item: TAdmarktItem;
  isHorizontal: boolean;
};

const AdmarktSimilarItem = ({ item, isHorizontal }: TAdmarktSimilarItemProps) => {
  const { t } = useI18nContext();

  const formattedPrice = formatPrice(item.price, t);
  const imageUrl = getLargeImageUrl(item.picture);
  const LinkComponent = item.thinContent ? MaskedLink : Link;

  if (isHorizontal) {
    return (
      <LinkComponent kind="block" className={Classes.item} href={item.url} data-ad-url={item.url}>
        <VerticalListing title={item.title} price={formattedPrice} imageSrc={imageUrl} imageLoading="lazy" />
      </LinkComponent>
    );
  }

  return (
    <MaskedLink kind="block" href={item.url}>
      <VerticalListing
        title={item.title}
        price={formattedPrice}
        imageSrc={imageUrl}
        imageLoading="lazy"
        decoding="async"
      />
    </MaskedLink>
  );
};

export default AdmarktSimilarItem;
