import { formatImageUrl, ImageSize } from '@ecg-marktplaats/media-util-js-lib';
import type { I18nContextType } from '@ecg-marktplaats/js-react-i18n';
import { TListingClient } from 'types/TListing';

import formatPrice from './formatPrice';

const RECENTLY_VIEWED_VIPS_STORAGE_KEY = 'RecentlyViewedVips';
const ITEM_LIMIT = 100;

const storeToRecentlyViewedVips = (t: I18nContextType['t'], listing: TListingClient): void => {
  const { title, itemId, gallery, priceInfo, category } = listing;
  const firstUrl = gallery?.imageUrls?.[0];

  try {
    // Retrieve recently viewed VIP items from storage
    const itemsStringValue = localStorage.getItem(RECENTLY_VIEWED_VIPS_STORAGE_KEY);
    const items = itemsStringValue ? JSON.parse(itemsStringValue) : [];

    const formattedImageUrl = formatImageUrl(firstUrl, ImageSize.Large);

    const newItem = {
      title,
      adUrn: itemId,
      imageUrl: formattedImageUrl,
      price: formatPrice(priceInfo, t, listing.isCarAd),
      category,
    };

    // if the item already exists at the list, remove it to push it higher
    const filteredItems = items.filter((item) => item.adUrn !== itemId);
    const newItems = [newItem, ...filteredItems].slice(0, ITEM_LIMIT);

    localStorage.setItem(RECENTLY_VIEWED_VIPS_STORAGE_KEY, JSON.stringify(newItems));
  } catch (e) {}
};

const removeFromRecentlyViewedVips = (itemId?: string): void => {
  if (!itemId) {
    return;
  }

  try {
    const itemsStringValue = localStorage.getItem(RECENTLY_VIEWED_VIPS_STORAGE_KEY);
    const items = itemsStringValue ? JSON.parse(itemsStringValue) : [];

    const filteredItems = items.filter((item) => item.adUrn !== itemId);
    localStorage.setItem(RECENTLY_VIEWED_VIPS_STORAGE_KEY, JSON.stringify(filteredItems));
  } catch (e) {}
};

export { storeToRecentlyViewedVips, removeFromRecentlyViewedVips };
