import { currency } from '@ecg-marktplaats/aurora-js-formatters';
import type { I18nContextType } from '@ecg-marktplaats/js-react-i18n';
import { TListingPrice } from 'types/TListing';
import { priceTypes } from '../../../server/utils/constants';

const { FIXED, MIN_BID } = priceTypes;

/**
 * Formats price name or monetary value
 * Ported from @ecg-marktplaats/aurora-templates/src/helpers/formatPrice.js
 */
export default (price: TListingPrice, t: I18nContextType['t'], suppressZeroCents: boolean = false): string => {
  const { priceCents, priceType } = price;
  const hasMonetaryValue = [FIXED, MIN_BID].includes(priceType);

  return hasMonetaryValue ? currency(priceCents, true, suppressZeroCents) : t(`priceTypes:prices.${priceType}`);
};
