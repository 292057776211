import React from 'react';
import { Icon } from '@hz-design-system/web-ui';
import { useI18nContext } from '@ecg-marktplaats/js-react-i18n';

import { TSellerType } from 'types/TListing';

import Classes from './SellerInfo.scss';

type TSellerIdentityProps = {
  sellerType: TSellerType;
};

const SellerIdentityIcon = ({ sellerType }: TSellerIdentityProps) => {
  const { t } = useI18nContext();

  switch (sellerType) {
    case 'CONSUMER': {
      return (
        <Icon
          name="profile"
          size="m"
          color="signal-action-default"
          className={Classes.icon}
          title={t('sellerInfo.identity.consumerToolTip')}
        />
      );
    }
    case 'TRADER': {
      return (
        <Icon
          name="shop"
          size="m"
          color="signal-action-default"
          className={Classes.icon}
          title={t('sellerInfo.identity.trader')}
        />
      );
    }
    case 'UNKNOWN':
    case 'ERROR':
    default: {
      return null;
    }
  }
};
export default SellerIdentityIcon;
