import React from 'react';

import { EnvironmentProvider } from '../../contexts/EnvironmentContext';
import SocialShare from './components/SocialShare/SocialShare';
import { TListingClient } from 'types/TListing';

type TSocialShareAppProps = {
  appState: {
    listing: TListingClient;
  };
  environment: any;
};

const SocialShareApp = ({ appState, environment }: TSocialShareAppProps) => {
  const { listing } = appState;
  return (
    <EnvironmentProvider value={environment}>
      <SocialShare listing={listing} />
    </EnvironmentProvider>
  );
};

export default SocialShareApp;
