import fetchAPI from '../../../utils/fetchAPI';
import { TSellerResponseRate } from 'types/TSellerResponseRate';

type TParams = {
  sellerId: number;
};

export default function getSellerResponseRateFromClient({ sellerId }: TParams): Promise<TSellerResponseRate> {
  try {
    return fetchAPI({
      url: `/v/api/seller-response-rate/${encodeURIComponent(sellerId)}`,
    });
  } catch (e) {
    return Promise.resolve({}) as Promise<TSellerResponseRate>;
  }
}
