import React, { useEffect, useRef, useState } from 'react';
import { Icon, NoOpLink, BlockLink } from '@hz-design-system/web-ui';
import { useI18nContext } from '@ecg-marktplaats/js-react-i18n';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader

import { getHeroImageUrl } from '../../../../utils/getImageUrls';
import useCarousel from './hooks/useCarousel';

import NoImage from './NoImage';
import Thumbnails from './Thumbnails';
import HeroImage from './HeroImage';
import MicroTipText from './MicroTipText';

import { TListingGallery } from 'types/TListing';

import Classes from './Gallery.scss';

let CarouselDialog;
let VideoModal;

type TGalleryProps = {
  gallery: TListingGallery;
  title: string;
  showMicroTipText?: boolean;
  showLargePhotos?: boolean;
};

const Gallery = ({
  gallery: {
    imageUrls = [],
    microTipText,
    media: { video, imageSizes },
    alt,
  },
  title,
  showMicroTipText = false,
  showLargePhotos,
}: TGalleryProps) => {
  const { t } = useI18nContext();
  const galleryContainerElement = useRef<HTMLHeadingElement>(null);
  const hasImage = Boolean(imageUrls.length);
  const heroImage = hasImage ? getHeroImageUrl(imageUrls[0], imageSizes) : '';
  const thumbnailItemWidth = 65;
  // eslint-disable-next-line no-magic-numbers
  const visibleThumbnailCountOnLoad = showLargePhotos ? 7 : 5;

  const [showNavigation, setShowNavigation] = useState(imageUrls.length > visibleThumbnailCountOnLoad);
  const [showCarouselDialog, setShowCarouselDialog] = useState(false);
  const [isCarouselDialogLoaded, setCarouselDialogLoaded] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [isVideoModalLoaded, setVideoModalLoaded] = useState(false);
  const [imageIndex, setImageIndex] = useState(1);

  const toggleCarouselModal = () => {
    setShowCarouselDialog(!showCarouselDialog);
  };

  const toggleVideoModal = () => {
    setShowVideoModal(!showVideoModal);
  };

  const changeCarouselIndex = (index) => {
    setImageIndex(index + 1);
  };

  useEffect(() => {
    if (galleryContainerElement?.current) {
      const containerWidth = galleryContainerElement.current.clientWidth;
      setShowNavigation(containerWidth < imageUrls.length * thumbnailItemWidth);
    }
  }, [imageUrls.length]);

  useEffect(() => {
    (async () => {
      if (!isCarouselDialogLoaded) {
        try {
          CarouselDialog = (await import(/* webpackChunkName: "CarouselDialog" */ './CarouselDialog')).default;
          setCarouselDialogLoaded(true);
        } catch (err) {}
      }
    })();
  }, [isCarouselDialogLoaded]);

  useEffect(() => {
    if (video && !isVideoModalLoaded) {
      (async () => {
        try {
          VideoModal = (await import(/* webpackChunkName: "VideoModal" */ './VideoModal')).default;
          setVideoModalLoaded(true);
        } catch (err) {}
      })();
    }
  }, [video, isVideoModalLoaded]);

  const { ModalCarousel, GalleryCarousel, showCarousel } = useCarousel({
    imageUrls,
    imageSizes,
    title,
    alt,
    changeCarouselIndex,
    toggleCarouselModal,
    showLargePhotos,
  });

  return (
    <>
      <div
        ref={galleryContainerElement}
        className={`${Classes.root} ${showLargePhotos ? Classes.showLargePhotos : ''}`}
      >
        {showMicroTipText && microTipText && <MicroTipText microTipText={microTipText} />}
        {!hasImage && <NoImage />}
        {hasImage && !showCarousel && <HeroImage imageUrl={heroImage} alt={alt} onClickHandler={toggleCarouselModal} />}
        {showCarousel && (
          <>
            <GalleryCarousel index={imageIndex - 1} />
            <div className={Classes.carouselIndexContainer}>
              <span className={Classes.carouselIndex}>
                {imageIndex}/{imageUrls.length}
              </span>
            </div>
          </>
        )}

        {imageUrls.length > 1 && (
          <Thumbnails
            onChange={changeCarouselIndex}
            imageUrls={imageUrls}
            imageSizes={imageSizes}
            showNavigation={showNavigation}
            activeImageIndex={imageIndex - 1}
            maxVisibleImageCount={visibleThumbnailCountOnLoad}
          />
        )}

        <div className={Classes.actions}>
          {video && (
            <div className={Classes.videoLink}>
              <BlockLink onClick={toggleVideoModal}>
                <Icon name="player-play" color="signal-link-default" size="m" />
                <NoOpLink>{t('gallery.show-video')}</NoOpLink>
              </BlockLink>
            </div>
          )}

          <div className={Classes.zoom}>
            <BlockLink onClick={toggleCarouselModal}>
              <Icon name="zoom-in" color="signal-link-default" size="m" />
              <NoOpLink>{t('gallery.zoom')}</NoOpLink>
            </BlockLink>
          </div>
        </div>
      </div>
      {isCarouselDialogLoaded && showCarouselDialog && ModalCarousel && (
        <CarouselDialog
          imageIndex={imageIndex}
          totalImageCount={imageUrls.length}
          title={title}
          toggleCarouselModal={toggleCarouselModal}
        >
          <ModalCarousel index={imageIndex - 1} />
        </CarouselDialog>
      )}
      {showVideoModal && isVideoModalLoaded && (
        <VideoModal video={video} toggleVideoModal={toggleVideoModal} title={t('gallery.video-title')} />
      )}
    </>
  );
};

export default Gallery;
