import { TListingSellerInfo } from 'types/TListing';
import type { I18nContextType } from '@ecg-marktplaats/js-react-i18n';

// ADR-028 https://github.mpi-internal.com/ecg-marktplaats/vostok-adr/blob/master/adr-028-location-formatting.md
// Same logic as in LRP
export default function getFormattedLocation(location: TListingSellerInfo['location'], t: I18nContextType['t']) {
  if (!location) {
    return '';
  }

  const { cityName, countryName, isAbroad } = location;

  if (!isAbroad) {
    return cityName || t('sellerInfo.location.nationwide');
  }

  if (cityName && countryName) {
    return `${cityName}, ${countryName}`;
  }

  if (countryName && !cityName) {
    return countryName;
  }

  return t('sellerInfo.location.abroad');
}
