import fetchAPI from '../../../utils/fetchAPI';
import { TAdmarktItemClient } from 'types/TAdmarkt';

type TParams = {
  sellerId: number;
  itemId: string;
  l2CategoryId: number;
};

export default async function getSimilarIteams({
  sellerId,
  itemId,
  l2CategoryId,
}: TParams): Promise<TAdmarktItemClient[]> {
  try {
    const sellerIdEncoded = encodeURIComponent(sellerId);
    const itemIdEncoded = encodeURIComponent(itemId);
    const l2CategoryIdEncoded = encodeURIComponent(l2CategoryId);
    return await fetchAPI({
      url: `/v/api/similar-items?sellerId=${sellerIdEncoded}&itemId=${itemIdEncoded}&l2CategoryId=${l2CategoryIdEncoded}`,
    });
  } catch (e) {
    return [];
  }
}
