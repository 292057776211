import React, { useContext, useState } from 'react';
import { Icon, Link } from '@hz-design-system/web-ui';
import { useI18nContext } from '@ecg-marktplaats/js-react-i18n';

import Classes from './ModalLink.scss';
import { trackGAEvent } from '../../../../../../utils/gaTracking';
import { GA_TRACK_EVENTS_ACTIONS, GA_TRACK_EVENTS_LABELS } from '../../../../../../utils/constants';
import EnvironmentContext from '../../../../../../contexts/EnvironmentContext';
import { TModalProps } from '../Modal/Modal';

type TShippingModalLinkProps = {
  formattedMinCost?: string;
  children?: React.ReactNode[] | React.ReactNode;
};

let Modal: React.FC<TModalProps>;

const ShippingModalLink = ({ formattedMinCost, children }: TShippingModalLinkProps) => {
  const { t } = useI18nContext();
  const { userDetails } = useContext(EnvironmentContext) || {};

  const [isOpen, setIsOpen] = useState(false);
  const [isModalLoaded, setIsModalLoaded] = useState(false);

  const loadModalComponent = async () => {
    Modal = (await import(/* webpackChunkName: "ShippingModal" */ '../Modal/Modal')).default;
    setIsModalLoaded(true);
  };

  const handleClick = () => {
    trackGAEvent(GA_TRACK_EVENTS_ACTIONS.Click, GA_TRACK_EVENTS_LABELS.Shipping, { category: 'VIP' });
    if (!isModalLoaded) {
      loadModalComponent();
    }
    setIsOpen(!isOpen);
  };

  return (
    <div className={Classes.shippingModalLink}>
      <Link onClick={handleClick} className={Classes.shippingLink} data-testid="shippingModalLink">
        {children ? (
          <div className={Classes.labelAndInfoIcon}>
            {children}
            <div className={Classes.infoIcon}>
              <Icon name="info" color="signal-link-default" size="m" />
            </div>
          </div>
        ) : (
          <>
            <div>
              <Icon name="truck" color="signal-link-default" size="m" />
            </div>
            <div className={Classes.labelAndInfoIcon}>
              <span className={Classes.label}>{t('shipping.costInfoFrom', { minimumCost: formattedMinCost })}</span>
              <div>
                <Icon name="info" color="signal-link-default" size="m" />
              </div>
            </div>
          </>
        )}
      </Link>
      {isModalLoaded && isOpen && <Modal toggle={handleClick} buyerId={userDetails?.id} />}
    </div>
  );
};

export default ShippingModalLink;
