import React, { useContext } from 'react';
import { Trans } from '@ecg-marktplaats/js-react-i18n';
import { Link, Image, Icon } from '@hz-design-system/web-ui';

import EnvironmentContext from '../../../../contexts/EnvironmentContext';

import EnergyLabel from './EnergyLabel';
import NapCarPassLogo from './NapCarPassLogo';
import {
  USP_BRAND_WARRANTY_ATTRIBUTE_KEY,
  USP_MILEAGE_ATTRIBUTE_KEY,
  USP_ENERGY_ATTRIBUTE_KEY,
} from '../../../../../../common/constants';

import { TListingClient, TListingUspInformation } from 'types/TListing';

import Classes from './CarUspBlocks.scss';

type TCarUspBlocksProps = {
  item: TListingUspInformation;
  carDetails?: TListingClient['carDetails'];
  hasUspWarrantyInformation: boolean;
  handleOpenModal: Function;
};

const DEFAULT_USP_VALUE = '-';

const CarUspBlocksItem = ({ item, carDetails, hasUspWarrantyInformation, handleOpenModal }: TCarUspBlocksProps) => {
  const { tenantContext } = useContext(EnvironmentContext);

  // Render item with on click handler when warranty information provided
  if (item.value !== DEFAULT_USP_VALUE && item.key === USP_BRAND_WARRANTY_ATTRIBUTE_KEY && hasUspWarrantyInformation) {
    return (
      <div key={item.key} className={Classes.block}>
        <Link className={Classes.blockLink} onClick={handleOpenModal}>
          <div className={Classes.info}>
            <Trans tagName="div" i18nKey={`usps.${item.key}`} className={Classes.title} />
            <Icon name="info" size="s" />
          </div>
          <Image className={Classes.warrantyImage} src={item.value} alt={item.key} />
        </Link>
      </div>
    );
  }

  return (
    <div key={item.key} className={Classes.block}>
      <Trans i18nKey={`usps.${item.key}`} className={Classes.title} />
      {item.value !== DEFAULT_USP_VALUE && item.key === USP_ENERGY_ATTRIBUTE_KEY && (
        <EnergyLabel value={item.value} tenantContext={tenantContext} className={Classes.energyLabel} />
      )}
      {/* Render warranty image without on click handler when warranty information is not provided */}
      {item.value !== DEFAULT_USP_VALUE && item.key === USP_BRAND_WARRANTY_ATTRIBUTE_KEY && (
        <Image className={Classes.warrantyImage} src={item.value} alt="" />
      )}
      {(item.value === DEFAULT_USP_VALUE ||
        (item.key !== USP_ENERGY_ATTRIBUTE_KEY && item.key !== USP_BRAND_WARRANTY_ATTRIBUTE_KEY)) && (
        <div className={Classes.value}>{item.value}</div>
      )}
      {item.key === USP_MILEAGE_ATTRIBUTE_KEY && <NapCarPassLogo carDetails={carDetails} />}
    </div>
  );
};

export default CarUspBlocksItem;
