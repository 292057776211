type TCustomDimension = Record<string, any> | '';

type TCreateCustomDimensionPayload = {
  dimension: number | string;
  payload?: any;
};

const createCustomDimension = ({ dimension, payload }: TCreateCustomDimensionPayload): TCustomDimension => {
  if (!payload) {
    return '';
  }
  if (typeof dimension === 'number') {
    return {
      [`dimension${dimension}`]: payload,
    };
  } else {
    return {
      [dimension]: payload,
    };
  }
};

export default createCustomDimension;
