import React, { useContext, useEffect, useState } from 'react';
import { useI18nContext } from '@ecg-marktplaats/js-react-i18n';

import { trackGAEvent } from '../../../../utils/gaTracking';
import EnvironmentContext from '../../../../contexts/EnvironmentContext';

import '../../LoginNudge.scss';

let LoginNudgeWidget;

const LoginNudge = () => {
  const { t } = useI18nContext();
  const {
    isBot,
    hasBannerBeenDismissed,
    userLoggedIn,
    links: { loginAccountUrl },
  } = useContext(EnvironmentContext);

  const [isLoginNudgeLoaded, setIsLoginNudgeLoaded] = useState(false);

  const loadLoginNudge = async () => {
    try {
      LoginNudgeWidget = (
        await import(
          /* webpackChunkName: "loginNudgeWidget" */ '@hz-design-system/web-ui/lib/apps/LoginNudge/LoginNudge'
        )
      ).default;
      setIsLoginNudgeLoaded(true);
    } catch (err) {}
  };

  useEffect(() => {
    if (!isBot && !userLoggedIn && !isLoginNudgeLoaded && hasBannerBeenDismissed) {
      loadLoginNudge();
      trackGAEvent('SeenNudge', 'LoginNudge');
    }
  }, [isBot, userLoggedIn, isLoginNudgeLoaded, hasBannerBeenDismissed]);

  if (!isLoginNudgeLoaded) {
    return null;
  }

  return (
    <LoginNudgeWidget
      translations={{
        greeting: t('loginNudge.greeting'),
        welcome: t('loginNudge.welcome'),
        loginText: t('loginNudge.loginText'),
        list: {
          firstLine: t('loginNudge.list.firstLine'),
          secondLine: t('loginNudge.list.secondLine'),
          thirdLine: t('loginNudge.list.thirdLine'),
        },
        loginButton: t('loginNudge.loginButton'),
      }}
      loginButtonSelector='[data-role="login"]'
      loginUrl={`${loginAccountUrl}?target=${encodeURIComponent(window.location.origin + window.location.pathname)}`}
    />
  );
};

export default LoginNudge;
