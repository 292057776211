import React, { ReactElement } from 'react';
import { PrimaryButton, SecondaryButton } from '@hz-design-system/web-ui';

type TSellerContactOptionsProps = {
  isPrimary: boolean;
  children: ReactElement | ReactElement[];
  className: string;
  onClick: Function;
  as?: 'button' | 'a';
  href?: string;
  target?: string;
  rel?: string;
};

const SellerContactOptionsButton = ({
  isPrimary,
  onClick,
  children,
  className,
  as,
  href,
  target,
  rel,
}: TSellerContactOptionsProps) => {
  if (isPrimary) {
    return (
      <PrimaryButton className={className} onClick={onClick} as={as} href={href} target={target} {...(rel && { rel })}>
        {children}
      </PrimaryButton>
    );
  }

  return (
    <SecondaryButton className={className} onClick={onClick} as={as} href={href} target={target} {...(rel && { rel })}>
      {children}
    </SecondaryButton>
  );
};

export default SellerContactOptionsButton;
