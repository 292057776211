import React, { useState } from 'react';

import CarUspBlocksItem from './CarUspBlocksItem';
import Classes from './CarUspBlocks.scss';

import { TListingClient } from 'types/TListing';

type TCarUspBlocksProps = {
  uspInformation?: TListingClient['uspInformation'];
  carDetails?: TListingClient['carDetails'];
  asHorizontalList: boolean;
};

let WarrantyModal;

const CarUspBlocks = ({ uspInformation, carDetails, asHorizontalList }: TCarUspBlocksProps) => {
  const [showWarrantyModal, setShowWarrantyModal] = useState(false);
  const [isWarrantyModalLoaded, setWarrantyModalLoaded] = useState(false);

  const uspWarrantyInformation = uspInformation?.find((usp) => Boolean(usp.warranty));
  const hasUspWarrantyInformation = Boolean(uspWarrantyInformation);

  const loadWarrantyModal = async () => {
    if (!isWarrantyModalLoaded) {
      try {
        WarrantyModal = (await import(/* webpackChunkName: "WarrantyModal" */ './WarrantyModal')).default;
        setWarrantyModalLoaded(true);
      } catch (err) {}
    }
  };

  const handleOpenModal = async () => {
    await loadWarrantyModal();
    setShowWarrantyModal(true);
  };

  const handleCloseModal = () => {
    setShowWarrantyModal(false);
  };

  if (!uspInformation) {
    return null;
  }

  return (
    <div className={`${Classes.root} ${asHorizontalList ? Classes.horizontal : ''}`}>
      {uspInformation.map((usp) => (
        <CarUspBlocksItem
          key={usp.key}
          item={usp}
          carDetails={carDetails}
          hasUspWarrantyInformation={hasUspWarrantyInformation}
          handleOpenModal={handleOpenModal}
        />
      ))}
      {showWarrantyModal && isWarrantyModalLoaded && (
        <WarrantyModal warranty={uspWarrantyInformation?.warranty} handleClose={handleCloseModal} />
      )}
    </div>
  );
};

export default CarUspBlocks;
