import React from 'react';

import { ButtonIcon, MaskedLink } from '@hz-design-system/web-ui';

import { trackGAEvent } from '../../../../../utils/gaTracking';
import createCustomDimension from '../../../../../utils/createCustomDimension';
import { GA_TRACK_EVENTS_ACTIONS, GA_PARAMETER } from '../../../../../utils/constants';
import Classes from './BuyItRefurbished.scss';
import { Trans } from '@ecg-marktplaats/js-react-i18n';

type TBuyItRefurbishedProps = {
  sellerName: string;
  refurbishedAdDeepLink: string;
  priceCents?: number;
  sellerId?: number;
  gaEvent?: {
    label: string;
  };
};

type THandleDeepLinkClickProps = {
  priceCents?: number;
  sellerId?: number;
  gaEvent?: {
    label: string;
  };
};

const handleDeepLinkClick = ({ priceCents, sellerId, gaEvent }: THandleDeepLinkClickProps) => {
  trackGAEvent(GA_TRACK_EVENTS_ACTIONS.BuyFromPartner, gaEvent ? gaEvent.label : '', {
    ...(priceCents &&
      createCustomDimension({
        dimension: GA_PARAMETER.ASKING_PRICE,
        payload: priceCents.toString(),
      })),
    ...createCustomDimension({
      dimension: GA_PARAMETER.AD_TYPE,
      payload: 'VerifiedRefurbished',
    }),
    ...(sellerId &&
      createCustomDimension({
        dimension: GA_PARAMETER.SELLER_ACCOUNT_ID,
        payload: sellerId.toString(),
      })),
  });
};

const BuyItRefurbished = ({
  sellerName,
  refurbishedAdDeepLink,
  priceCents,
  sellerId,
  gaEvent,
}: TBuyItRefurbishedProps) => {
  return (
    <MaskedLink
      target="_blank"
      rel="nofollow"
      href={refurbishedAdDeepLink}
      className={`hz-Button hz-Button--primary ${Classes.button} ${Classes.link} ${Classes.sellerCta}`}
      onClick={() => handleDeepLinkClick({ priceCents, sellerId, gaEvent })}
    >
      <ButtonIcon name="euros" isStartIcon={false} />
      <Trans tagName="span" i18nKey="sellerContact.buyItRefurbished" sellerName={sellerName} />
    </MaskedLink>
  );
};

export default BuyItRefurbished;
