import { TFollowSellerClient } from 'types/TFollowSeller';
import fetchAPI from '../../../utils/fetchAPI';

type TParams = {
  sellerId: number;
  follow: boolean;
  xsrfToken: string;
};

export default async function toggleFollowSeller({
  sellerId,
  follow,
  xsrfToken,
}: TParams): Promise<TFollowSellerClient> {
  try {
    return await fetchAPI({
      url: '/v/api/follow-seller',
      method: 'POST',
      xsrfToken,
      payload: { sellerId, follow },
    });
  } catch (e) {
    return {
      // Return the previous state if request failed
      followSeller: !follow,
    };
  }
}
