import { GA_TRACK_EVENTS_ACTIONS } from './constants';

const checkForAdditionalMetrics = (action: string): string => {
  const additionalMetricsObject = {
    [GA_TRACK_EVENTS_ACTIONS.R2SEmailSuccess]: 'metric4',
    [GA_TRACK_EVENTS_ACTIONS.R2SBidSuccess]: 'metric5',
    [GA_TRACK_EVENTS_ACTIONS.R2SPhoneBegin]: 'metric7',
    [GA_TRACK_EVENTS_ACTIONS.R2SSMSBegin]: 'metric8',
    [GA_TRACK_EVENTS_ACTIONS.R2SExternalBegin]: 'metric9',
    [GA_TRACK_EVENTS_ACTIONS.PostAdPaidSuccess]: 'metric2',
    [GA_TRACK_EVENTS_ACTIONS.FeatureAdSuccess]: 'metric10',
    [GA_TRACK_EVENTS_ACTIONS.PostAdFreeSuccess]: 'metric11',
  };
  return additionalMetricsObject[action];
};

type TEnrichWithAdditionalMetrics = {
  eventAction: string;
  eventLabel: string;
  [key: string]: string;
};

const enrichWithAdditionalMetrics = ({
  eventAction,
  eventLabel,
  ...options
}: TEnrichWithAdditionalMetrics): TEnrichWithAdditionalMetrics => {
  const enrichedKey = checkForAdditionalMetrics(eventAction);
  return {
    eventAction,
    eventLabel,
    ...(enrichedKey && {
      [enrichedKey]: 1,
    }),
    ...(options || {}),
  };
};

export default enrichWithAdditionalMetrics;
