import { useState, useCallback } from 'react';

type TUseToggle = [state: boolean, toggle: Function];

function useToggle(initialState: boolean = false, selector: string, attribute: string): TUseToggle {
  const [state, setState] = useState(initialState);

  const toggle = useCallback(() => {
    const descriptionRoot = document.querySelector(selector);
    descriptionRoot?.setAttribute(attribute, `${!state}`);

    setState(!state);
  }, [state, selector, attribute]);

  return [state, toggle];
}

export default useToggle;
