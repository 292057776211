import React, { useState } from 'react';
import { Trans, useI18nContext } from '@ecg-marktplaats/js-react-i18n';
import { Asset } from '@hz-design-system/web-ui';

import { TListingClient } from 'types/TListing';

import Classes from './NapCarPassLogo.scss';

type TNapCarPassLogoProps = {
  carDetails?: TListingClient['carDetails'];
};

let NapCarPassModal;

const NapCarPassLogo = ({ carDetails }: TNapCarPassLogoProps) => {
  const { t } = useI18nContext();
  const [isOpen, setIsOpen] = useState(false);
  const [isNapCarPassModalLoaded, setNapCarPassModalLoaded] = useState(false);
  let assetName;
  let logoClassName;

  if (carDetails?.hasNapStatus) {
    assetName = 'LogoNap';
    if (carDetails.licensePlate) {
      // while we can have non clickable nap logo we gonna use this ugly logoNapHover class
      logoClassName = `${Classes.logoNap} ${Classes.logoNapHover}`;
    } else {
      logoClassName = Classes.logoNap;
    }
  }

  if (carDetails?.carPassUrl) {
    assetName = 'LogoCarPass';
    logoClassName = Classes.logoCarPass;
  }

  if (!assetName) {
    return null;
  }

  const loadNapCarPassModal = async () => {
    if (!isNapCarPassModalLoaded) {
      try {
        if (carDetails?.carPassUrl) {
          NapCarPassModal = (
            await import(
              /* webpackChunkName: "BasicModalWithFooter" */ '@hz-design-system/web-ui/lib/components/Modal/BasicModalWithFooter'
            )
          ).default;
        } else {
          NapCarPassModal = (
            await import(
              /* webpackChunkName: "BasicModal" */ '@hz-design-system/web-ui/lib/components/Modal/BasicModal'
            )
          ).default;
        }
        setNapCarPassModalLoaded(true);
      } catch (err) {}
    }
  };

  const handleClick = async () => {
    if (carDetails?.licensePlate || carDetails?.carPassUrl) {
      await loadNapCarPassModal();
      setIsOpen(true);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const confirm = {
    text: t('listing.modal.napCarPass.confirm'),
    onClick: () => {
      handleClose();

      if (carDetails?.carPassUrl) {
        window.open(carDetails?.carPassUrl, '_blank');
      }
    },
  };

  return (
    <>
      <Asset.Fluid className={`${Classes.root} ${logoClassName}`} name={assetName} onClick={handleClick} />
      {isOpen && isNapCarPassModalLoaded && (
        <NapCarPassModal
          title={t('listing.modal.napCarPass.title')}
          size="s"
          confirm={confirm}
          onClose={handleClose}
          buttonsAlignmentMobile="stacked"
          buttonsAlignmentDesktop="stacked"
        >
          <Trans i18nKey={'listing.modal.napCarPass.text'} tagName="span" licensePlate={carDetails?.licensePlate} />
        </NapCarPassModal>
      )}
    </>
  );
};

export default NapCarPassLogo;
