const VIP_VIEW_COUNT_STORAGE_KEY = 'cars_vip_view_count';

const storeVipViewCount = (isCarAd): void => {
  if (!isCarAd) {
    return;
  }

  try {
    const itemValue = sessionStorage.getItem(VIP_VIEW_COUNT_STORAGE_KEY);
    const count = itemValue ? JSON.parse(itemValue) : 0;
    sessionStorage.setItem(VIP_VIEW_COUNT_STORAGE_KEY, count + 1);
  } catch (e) {}
};

export default storeVipViewCount;
