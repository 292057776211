import React, { useState, useEffect } from 'react';
import { TListingGallery } from 'types/TListing';

type TUseCarousel = {
  imageUrls: TListingGallery['imageUrls'];
  imageSizes?: TListingGallery['media']['imageSizes'];
  toggleCarouselModal: (index: any) => void;
  title: string;
  alt: string;
  changeCarouselIndex: (index: any) => void;
  showLargePhotos?: boolean;
};

type TCarouselProps = {
  index: number;
};

let ModalCarousel;
let GalleryCarousel;

const useCarousel = ({
  imageUrls,
  imageSizes,
  toggleCarouselModal,
  alt,
  changeCarouselIndex,
  showLargePhotos,
}: TUseCarousel) => {
  const [showCarousel, setShowCarousel] = useState(false);

  useEffect(() => {
    const loadComponent = async () => {
      try {
        const CarouselWrapper = (await import(/* webpackChunkName: "carousel" */ '../CarouselWrapper')).default;

        const carouselGallery = ({ index }) => (
          <CarouselWrapper
            onClickItemHandler={toggleCarouselModal}
            imageUrls={imageUrls}
            imageSizes={imageSizes}
            onChangeHandler={changeCarouselIndex}
            index={index}
            alt={alt}
            showLargePhotos={showLargePhotos}
          />
        );

        const carouselModal = ({ index }) => (
          <CarouselWrapper
            imageUrls={imageUrls}
            onChangeHandler={changeCarouselIndex}
            index={index}
            isModal={true}
            alt={alt}
          />
        );

        GalleryCarousel = ({ index }: TCarouselProps) => carouselGallery({ index });
        ModalCarousel = ({ index }: TCarouselProps) => carouselModal({ index });

        setShowCarousel(imageUrls.length > 1);
      } catch (err) {}
    };

    loadComponent();
  }, [imageUrls.length]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    ModalCarousel,
    GalleryCarousel,
    showCarousel,
  };
};

export default useCarousel;
