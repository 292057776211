import type { I18nContextType } from '@ecg-marktplaats/js-react-i18n';
import parse from 'date-fns/parse';
import differenceInDays from 'date-fns/difference_in_days';
import differenceInWeeks from 'date-fns/difference_in_weeks';
import differenceInMonths from 'date-fns/difference_in_months';
import differenceInYears from 'date-fns/difference_in_years';

export default (activeSince: string | Date, t: I18nContextType['t']): string => {
  let unit;
  let count;

  const now = new Date();
  const since = parse(activeSince);
  const years = differenceInYears(now, since);
  const months = differenceInMonths(now, since);
  const weeks = differenceInWeeks(now, since);
  const days = differenceInDays(now, since);
  // eslint-disable-next-line no-magic-numbers
  const withHalfYear = years > 0 && months % 12 > 6;

  if (withHalfYear) {
    unit = 'yearWithHalf';
    count = years;
  } else if (years > 0) {
    unit = 'year';
    count = years;
  } else if (months > 0) {
    unit = 'month';
    count = months;
  } else if (weeks > 0) {
    unit = 'week';
    count = weeks;
  } else {
    unit = 'day';
    count = days;
  }

  return t(`date.${unit}`, { count });
};
