export const MILEAGE_REGEX = new RegExp('^([0-9]{1,3}(.?[0-9]{3})*)$');
// This regex checks if the name contains url
export const CONTAINS_URL_REGEX = new RegExp(
  '(http(s)?:\\/\\/.)?(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_\\+.~#?&=]*)',
);
export const IS_YES_NO_OR_NUMERIC_REGEX = new RegExp(/(^Ja$)|(^Nee$)|(^Oui$)|(^Non$)|(^\d+$)/);

export const MIN_ASQ_MESSAGE_LENGTH = 2;
export const MAX_ASQ_MESSAGE_LENGTH = 5000;
export const MAX_ASQ_NAME_LENGTH = 31;
export const MAX_ASQ_LOCATION_LENGTH = 100;
export const MAX_ASQ_MILEAGE_LENGTH = 10;

export const USP_BOVAG_WARRANTY_ATTRIBUTE_KEY = 'bovagWarranty';
export const USP_BOVAG_WARRANTY_ATTRIBUTE_YES_VALUE = 'Ja';
export const USP_BRAND_WARRANTY_ATTRIBUTE_KEY = 'brandGuarantee';
export const USP_ENERGY_ATTRIBUTE_KEY = 'energyLabel';
export const USP_MILEAGE_ATTRIBUTE_KEY = 'mileage';
export const USP_FUEL_ATTRIBUTE_KEY = 'fuel';
export const USP_FUEL_ATTRIBUTE_HYBRID_BENZINE_VALUE = 13838;
export const USP_FUEL_ATTRIBUTE_HYBRID_DIESEL_VALUE = 13839;
export const USP_FUEL_ATTRIBUTE_ELECTRIC_VALUE = 11756;
export const USP_FUEL_CONSUMPTION_ATTRIBUTE_KEY = 'fuelConsumption';
export const USP_CONSTRUCTION_YEAR_ATTRIBUTE_KEY = 'constructionYear';
export const USP_CO2EMMISSION_ATTRIBUTE_KEY = 'co2emission';
export const USP_EURONORMBE_ATTRIBUTE_KEY = 'euronormBE';
export const USP_TRANSMISSION_ATTRIBUTE_KEY = 'transmission';
export const USP_BODY_TYPE_ATTRIBUTE_KEY = 'body';

export const ANIMAL_CATEGORY_ID = 395;
export const DELIVERY_ATTRIBUTE = 'delivery';
export const PRICE_TYPE_ATTRIBUTE = 'priceType';
export const PRICE_TYPE_ATTRIBUTE_VALUE_NL = 'Lease';
export const PRICE_TYPE_ATTRIBUTE_VALUE_FR = 'Leasing';
export const LEASE_TYPE_ATTRIBUTE = 'leaseType';
export const LEASE_TYPE_ATTRIBUTE_VALUE_NL = 'Persoonlijke lening';

export const SELLER_OTHER_ITEMS_LIMIT = 3;

/**
 * List of level 1 categories to exclude from showing up BIN button on the VIP
 *  91 - Auto’s
 *  289 - Caravans and camping
 *  1032 - Houses and rooms
 *  678 - Motorcycles
 *  976 - Water sports and Boats
 */
// eslint-disable-next-line no-magic-numbers
export const BIN_EXCLUDED_CATEGORIES = [91, 289, 1032, 678, 976];
export const ALT_TEXT_LENGTH_LIMIT = 125;

export const VERIFIED_REFURBISHED_ATTRIBUTES = ['website', 'deeplink'];
