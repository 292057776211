import React from 'react';

import { EnvironmentProvider } from '../../contexts/EnvironmentContext';
import AdmarktSimilarItemsContainer from './containers/AdmarktSimilarItemsContainer';
import { TListingClient } from 'types/TListing';

type TAdmarktSimilarItemsAppProps = {
  appState: {
    listing: {
      itemId: TListingClient['itemId'];
      seller: TListingClient['seller'];
      category: TListingClient['category'];
      flags: TListingClient['flags'];
    };
  };
  environment: any;
};

const AdmarktSimilarItemsApp = ({ appState, environment }: TAdmarktSimilarItemsAppProps) => {
  const {
    listing: {
      itemId,
      seller,
      category,
      flags: { requestRelevantItems, isAdmarkt },
    },
  } = appState;

  if (isAdmarkt || !requestRelevantItems) {
    return null;
  }

  return (
    <EnvironmentProvider value={environment}>
      <AdmarktSimilarItemsContainer itemId={itemId} sellerId={seller.id} category={category} />
    </EnvironmentProvider>
  );
};

export default AdmarktSimilarItemsApp;
