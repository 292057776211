import { ImageSize, formatImageUrl } from '@ecg-marktplaats/media-util-js-lib';
import type { TListingGallery } from 'types/TListing';

type TImageSizes = TListingGallery['media']['imageSizes'];

export const getThumbnailImageUrl = (base: string = '', sizes?: TImageSizes): string =>
  formatImageUrl(base, ImageSize.ExtraExtraSmall, sizes);

export const getHeroImageUrl = (base: string = '', sizes?: TImageSizes): string =>
  formatImageUrl(base, ImageSize.ExtraLarge, sizes);

export const getLargeHeroImageUrl = (base: string = '', sizes?: TImageSizes): string =>
  formatImageUrl(base, ImageSize.ExtraExtraLarge, sizes);

export const getExtraLargeImageUrl = (base: string = '', sizes?: TImageSizes): string =>
  formatImageUrl(base, ImageSize.ExtraExtraExtraLarge, sizes);

export const getLargeImageUrl = (base: string = '', sizes?: TImageSizes): any =>
  formatImageUrl(base, ImageSize.Large, sizes);
