import React from 'react';
import { BrandBadge } from '@hz-design-system/web-ui';

import Classes from './MicroTipText.scss';
import { TListingGallery } from 'types/TListing';

type TMicroTipTextProps = {
  microTipText: TListingGallery['microTipText'];
};

const MicroTipText = ({ microTipText }: TMicroTipTextProps) => (
  <BrandBadge className={Classes.root}>{microTipText}</BrandBadge>
);

export default MicroTipText;
