import { useEffect } from 'react';

const ATTRIBUTE = 'data-action';

const handleClickEvent = (event) => {
  event.preventDefault();

  const { action } = (event.currentTarget as HTMLElement)?.dataset;

  switch (action) {
    case 'history.back':
      history.back();
      break;
  }
};

/**
 * Attaches `onClick` event handler to perform onclick action on *SSR-only* elements
 * Used for elements that are not rehydrated client-side.
 */
const useOnClickActionForSSR = function () {
  useEffect(() => {
    document.querySelectorAll(`[${ATTRIBUTE}]`).forEach((item) => {
      item.addEventListener('click', handleClickEvent, false);
    });
  }, []); // `[]` to run effect only ones
};

/**
 * Returns attribute to enable tracking the click in GA
 * Apply for links in app-shell that are not rehydrated client-side
 */
const formatOnClickActionForSSR = (action: string): object => ({
  [ATTRIBUTE]: `${encodeURIComponent(action)}`,
});

export { formatOnClickActionForSSR, useOnClickActionForSSR };
