import React from 'react';

import { EnvironmentProvider } from '../../contexts/EnvironmentContext';

import Adsense from './components/Adsense/Adsense';

import { TListingClient } from 'types/TListing';

type TAdsenseAppProps = {
  environment: any;
  appState: {
    listing: {
      title: TListingClient['title'];
    };
  };
};

const AdsenseApp = ({ appState, environment }: TAdsenseAppProps) => {
  const {
    listing: { title },
  } = appState;

  return (
    <EnvironmentProvider value={environment}>
      <Adsense title={title} />
    </EnvironmentProvider>
  );
};
export default AdsenseApp;
