import React from 'react';
import { EnvironmentProvider } from '../../contexts/EnvironmentContext';
import LoginNudge from './components/LoginNudge/LoginNudge';

type TLoginNudgeAppProps = {
  environment: any;
};

const LoginNudgeApp = ({ environment }: TLoginNudgeAppProps) => (
  <EnvironmentProvider value={environment}>
    <LoginNudge />
  </EnvironmentProvider>
);

export default LoginNudgeApp;
