import React, { useState, useContext } from 'react';
import { Link, StarRating } from '@hz-design-system/web-ui';
import { Trans, useI18nContext } from '@ecg-marktplaats/js-react-i18n';

import EnvironmentContext from '../../../../contexts/EnvironmentContext';

import { TSellerProfileClient } from 'types/TSellerProfile';
import { TListingSellerInfo } from 'types/TListing';
import { TSellerPublicProfile } from 'types/TSellerPublicProfile';

import Classes from './SellerInfo.scss';

let SellerExternalReviewDialog;

type TSellerExternalReviewsProps = {
  sellerName: TListingSellerInfo['name'];
  averageScore: TSellerProfileClient['averageScore'];
  numberOfReviews: TSellerProfileClient['numberOfReviews'];
  provider: TSellerProfileClient['provider'];
  externalReviews: TSellerProfileClient['externalReviews'];
  showProfilePicture: boolean;
  profilePictures: TSellerPublicProfile['profilePictures'];
};

const SellerExternalReviews = ({
  sellerName,
  averageScore,
  numberOfReviews,
  provider,
  externalReviews,
  showProfilePicture,
  profilePictures,
}: TSellerExternalReviewsProps) => {
  const { t } = useI18nContext();
  const { links: { seller: sellerLinks = {} } = {} } = useContext(EnvironmentContext);
  const [isOpen, setIsOpen] = useState(false);
  const [isSellerReviewDialogLoaded, setSellerReviewDialogLoaded] = useState(false);

  if (!numberOfReviews || !averageScore) {
    return null;
  }

  const loadSellerReviewDialog = async () => {
    if (!isSellerReviewDialogLoaded) {
      try {
        SellerExternalReviewDialog = (
          await import(/* webpackChunkName: "SellerReviewDialog" */ './SellerExternalReviewDialog')
        ).default;
        setSellerReviewDialogLoaded(true);
      } catch (err) {}
    }
  };

  const handleClick = (event) => {
    event.preventDefault();
    loadSellerReviewDialog();
    setIsOpen(true);
  };
  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <div className={Classes.row}>
      <StarRating label="" rating={averageScore} size="m" />
      <Link href={sellerLinks.reviews} onClick={handleClick}>
        <Trans tagName="span" i18nKey="sellerInfo.review" count={numberOfReviews} />
      </Link>
      {isSellerReviewDialogLoaded && isOpen && (
        <SellerExternalReviewDialog
          title={t('sellerInfo.externalReviewDialogTitle')}
          reviewsText={t('sellerInfo.externalReviewDialogText', { count: numberOfReviews })}
          providedBy={t('sellerInfo.externalReviewDialogProvided', { provider })}
          onClose={handleClose}
          sellerName={sellerName}
          averageScore={averageScore}
          reviews={externalReviews}
          showProfilePicture={showProfilePicture}
          profilePictures={profilePictures}
        />
      )}
    </div>
  );
};

export default SellerExternalReviews;
