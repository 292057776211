import React from 'react';
import { hydrateRoot, createRoot } from 'react-dom/client';
import { I18nContextProvider } from '@ecg-marktplaats/js-react-i18n';
import { Header } from '@hz-design-system/web-ui';

import LoginNudgeApp from '../../apps/LoginNudgeApp/LoginNudgeApp';
import ListingApp from '../../apps/ListingApp/ListingApp';
import DescriptionButtonApp from '../../apps/DescriptionButtonApp/DescriptionButtonApp';
import SellerSidebarApp from '../../apps/SellerSidebarApp/SellerSidebarApp';
import BiddingApp from '../../apps/BiddingApp/BiddingApp';
import AdmarktSimilarItemsApp from '../../apps/AdmarktSimilarItemsApp/AdmarktSimilarItemsApp';
import ReportApp from '../../apps/ReportApp/ReportApp';
import SocialShareApp from '../../apps/SocialShareApp/SocialShareApp';
import AdsenseApp from '../../apps/AdsenseApp/AdsenseApp';
import { BANNER_POSITION, bannerPositions } from '../../apps/BannersApp/constants/banners';
import BannersApp from '../../apps/BannersApp/BannersApp';
import InspectionServiceApp from '../../apps/InspectionServiceApp/InspectionServiceApp';

import { trackGAEvent } from '../../utils/gaTracking';

import '../../../scss/global.scss';
import '../../../scss/layout.scss';

// server side only components styles for this page
import '../../../../server/pages/vip.styles';

export default (i18n) => {
  // eslint-disable-next-line no-underscore-dangle
  const appState = window.__CONFIG__ || {};
  const environment = window.__ENV_CONFIG__ || {};
  const headerConfig = window.__HEADER_CONFIG__ || {};
  const {
    listing: {
      bidsInfo: { isBiddingEnabled },
      flags: { showBannersOnVip, showExternalAds, requestFeeds, withBannersFromSeller, isLeaseCar },
      carDetails,
    },
  } = appState;

  const {
    consentedToAllPurposes,
    inspectionServiceEnabled,
    experiments: { isSourcepointAsfshExperimentEnabled },
  } = environment;
  const hasConsent = isSourcepointAsfshExperimentEnabled ? consentedToAllPurposes : true;

  const smartBannerAndroidRoot = document.getElementById('smart-banner-android-root');
  const headerRootContainer = document.getElementById('header-root');
  const loginNudgeRoot = document.getElementById('login-nudge-root');
  const listingRoot = document.getElementById('listing-root');
  const descriptionSellerWebsiteLinkRoot = document.getElementById('description-seller-website-link-root');
  const descriptionButtonRoot = document.getElementById('description-button-root');
  const sellerSidebarRoot = document.getElementById('seller-sidebar-root');
  const biddingRoot = document.getElementById('bidding-root');
  const similarItemsRoot = document.getElementById('similar-items-root');
  const reportRoot = document.getElementById('report-root');
  const sellerOtherItemsRoot = document.getElementById('seller-other-items-root');
  const socialShareRoot = document.getElementById('social-share-root');
  const adsenseRootContainer = document.getElementById('adsense-root');

  // BNL12606 track visibility tracking to be removed when experiment cleaned up
  // this need to be tracked for A & B
  (() => {
    const trackerEl = document.getElementById('sustainability-visibility-tracker');
    if (trackerEl) {
      const checkScroll = function () {
        const rect = trackerEl.getBoundingClientRect();
        if (
          rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
          rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        ) {
          trackGAEvent('InViewport', 'SustainabilityBlock');
          document.removeEventListener('scroll', checkScroll);
        }
      };
      document.addEventListener('scroll', checkScroll, {
        passive: true,
      });
      document.addEventListener('unload', () => {
        document.removeEventListener('scroll', checkScroll);
      });
    }
  })();

  // Render smart banner for Android
  // in case when this is not an Android there is no element to hydrate
  // @ts-ignore
  if (environment?.hasSmartBannerAndroidEnabled) {
    const loadComponent = async () => {
      try {
        const SmartBannerAndroidApp = (
          await import(
            /* webpackChunkName: "SmartBannerAndroidApp" */ '../../apps/SmartBannerAndroidApp/SmartBannerAndroidApp'
          )
        ).default;
        hydrateRoot(smartBannerAndroidRoot!, <SmartBannerAndroidApp />);
      } catch (err) {}
    };
    loadComponent();
  }

  // Header is server side rendered in express-react library
  hydrateRoot(headerRootContainer!, <Header headerConfig={headerConfig} />);

  hydrateRoot(
    loginNudgeRoot!,
    <I18nContextProvider i18nInstance={i18n}>
      <LoginNudgeApp environment={environment} />
    </I18nContextProvider>,
  );

  hydrateRoot(
    listingRoot!,
    <I18nContextProvider i18nInstance={i18n}>
      <ListingApp appState={appState} environment={environment} />
    </I18nContextProvider>,
  );

  import('../../apps/DescriptionSellerWebsiteApp/DescriptionSellerWebsiteLinkApp').then(
    ({ default: DescriptionSellerWebsiteLinkApp }) =>
      hydrateRoot(
        descriptionSellerWebsiteLinkRoot!,
        <I18nContextProvider i18nInstance={i18n}>
          <DescriptionSellerWebsiteLinkApp appState={appState} environment={environment} />
        </I18nContextProvider>,
      ),
  );

  hydrateRoot(
    descriptionButtonRoot!,
    <I18nContextProvider i18nInstance={i18n}>
      <DescriptionButtonApp />
    </I18nContextProvider>,
  );

  hydrateRoot(
    sellerSidebarRoot!,
    <I18nContextProvider i18nInstance={i18n}>
      <SellerSidebarApp appState={appState} environment={environment} />
    </I18nContextProvider>,
  );

  // in case when bidding is disabled there is no element to hydrate
  if (isBiddingEnabled) {
    hydrateRoot(
      biddingRoot!,
      <I18nContextProvider i18nInstance={i18n}>
        <BiddingApp appState={appState} environment={environment} />
      </I18nContextProvider>,
    );
  }

  // @ts-ignore
  hydrateRoot(
    similarItemsRoot!,
    <I18nContextProvider i18nInstance={i18n}>
      <AdmarktSimilarItemsApp appState={appState} environment={environment} />
    </I18nContextProvider>,
  );

  hydrateRoot(
    reportRoot!,
    <I18nContextProvider i18nInstance={i18n}>
      <ReportApp appState={appState} environment={environment} />
    </I18nContextProvider>,
  );

  import('../../apps/SellerOtherItemsApp/SellerOtherItemsApp').then(({ default: SellerOtherItemsApp }) =>
    hydrateRoot(
      sellerOtherItemsRoot!,
      <I18nContextProvider i18nInstance={i18n}>
        <SellerOtherItemsApp appState={appState} environment={environment} />
      </I18nContextProvider>,
    ),
  );

  if (showExternalAds && hasConsent) {
    const adsenseRoot = createRoot(adsenseRootContainer!);
    adsenseRoot.render(
      <I18nContextProvider i18nInstance={i18n}>
        <AdsenseApp appState={appState} environment={environment} />
      </I18nContextProvider>,
    );
  }

  hydrateRoot(
    socialShareRoot!,
    <I18nContextProvider i18nInstance={i18n}>
      <SocialShareApp appState={appState} environment={environment} />
    </I18nContextProvider>,
  );

  // Render banners
  // in case when banners are disabled there is no element to hydrate
  if (showBannersOnVip) {
    Object.values(BANNER_POSITION.STATIC).forEach((position) => {
      const bannersRootContainer = document.getElementById(bannerPositions[position].containerId);
      hydrateRoot(
        bannersRootContainer!,
        <I18nContextProvider i18nInstance={i18n}>
          <BannersApp position={position} environment={environment} />
        </I18nContextProvider>,
      );
    });
  }

  if (withBannersFromSeller) {
    const bannersRootContainer = document.getElementById(
      bannerPositions[BANNER_POSITION.DYNAMIC.DEALER_TOP].containerId,
    );
    hydrateRoot(
      bannersRootContainer!,
      <I18nContextProvider i18nInstance={i18n}>
        <BannersApp position={BANNER_POSITION.DYNAMIC.DEALER_TOP} environment={environment} />
      </I18nContextProvider>,
    );
  }

  if (inspectionServiceEnabled && !isLeaseCar && carDetails) {
    const inspectionServiceRoot = document.getElementById('inspection-service-root');
    hydrateRoot(
      inspectionServiceRoot!,
      <I18nContextProvider i18nInstance={i18n}>
        <InspectionServiceApp environment={environment} />
      </I18nContextProvider>,
    );
  }

  if (carDetails) {
    const loadComponent = async () => {
      try {
        const CarAttributesApp = (
          await import(/* webpackChunkName: "CarAttributesApp" */ '../../apps/CarAttributes/CarAttributesApp')
        ).default;
        const carAttributesAccordionRoot = document.getElementById('car-attributes-root');
        hydrateRoot(
          carAttributesAccordionRoot!,
          <I18nContextProvider i18nInstance={i18n}>
            <CarAttributesApp appState={appState} environment={environment} />
          </I18nContextProvider>,
        );
      } catch (err) {}
    };
    loadComponent();
  }

  if (requestFeeds) {
    const loadComponent = async () => {
      try {
        const FeedApp = (await import(/* webpackChunkName: "FeedApp" */ '../../apps/FeedApp/FeedApp')).default;
        const feedRoot = document.getElementById('feed-root');
        hydrateRoot(
          feedRoot!,
          <I18nContextProvider i18nInstance={i18n}>
            <FeedApp appState={appState} environment={environment} />
          </I18nContextProvider>,
        );
      } catch (err) {}
    };
    loadComponent();
  }
};
