import fetchAPI from '../../../utils/fetchAPI';

type TParams = {
  url: string;
  xsrfToken: string;
  encryptedUserId: string;
  fingerPrint: string;
  l1CategoryId: number;
  l1CategoryName: string;
  l2CategoryId: number;
  l2CategoryName: string;
  locale: string;
  tenant: string;
  uuid: string;
};

type TRsiSegments = {
  segments?: string;
};

export default async function getRsiSegments({
  url,
  xsrfToken,
  encryptedUserId,
  fingerPrint,
  l1CategoryId,
  l1CategoryName,
  l2CategoryId,
  l2CategoryName,
  locale,
  tenant,
  uuid,
}: TParams): Promise<TRsiSegments> {
  let rsiSegments;

  try {
    rsiSegments = await fetchAPI({
      url,
      method: 'POST',
      xsrfToken,
      payload: {
        device: 'web',
        encryptedUserId,
        fingerPrint,
        intent: 'vip_view',
        l1CategoryId,
        l1CategoryName,
        l2CategoryId,
        l2CategoryName,
        locale,
        searchAttributes: {},
        tenant,
        uuid,
      },
    });
  } catch (e) {}

  return rsiSegments;
}
