import React from 'react';
import SellerContactOptionsButton from '../SellerContactOptionsButton';
import { ButtonIcon } from '@hz-design-system/web-ui';

type Props = {
  onClick: () => void;
  isPrimary: boolean;
};

import Classes from '../SellerContactOptions.scss';
import { Trans } from '@ecg-marktplaats/js-react-i18n';

const PaymentProposalButton = ({ onClick, isPrimary }: Props) => {
  return (
    <>
      <SellerContactOptionsButton className={Classes.button} isPrimary={isPrimary} onClick={onClick}>
        <ButtonIcon name="euros" isStartIcon={false} />
        <Trans tagName="span" className={Classes.text} i18nKey="sellerContact.paymentProposal.button" />
      </SellerContactOptionsButton>
    </>
  );
};

export default PaymentProposalButton;
