const getHash = () => window?.location?.hash?.substring(1);

const setHash = (hashValue: string = '') => {
  window.location.hash = `#${hashValue}`;
};

const getHashParamValue = (key: string): string | null => {
  const hashParams = getHash();

  const searchParams = new URLSearchParams(hashParams);
  return searchParams.get(key);
};

const appendHashParam = (key: string, value: string): void => {
  const hashParams = getHash();

  const searchParams = new URLSearchParams(hashParams);
  searchParams.append(key, value);

  setHash(searchParams.toString());
};

const deleteHashParam = (key: string): void => {
  const hashParams = getHash();

  const searchParams = new URLSearchParams(hashParams);
  searchParams.delete(key);

  setHash(searchParams.toString());
};

const deleteAllHashParams = (): void => {
  setHash();
};

export { getHashParamValue, appendHashParam, deleteHashParam, deleteAllHashParams };
