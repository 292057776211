import React, { useEffect } from 'react';

import { BANNER_POSITION, bannerPositions } from '../../constants/banners';
import { displayBannerAfterInteraction } from './utils/banners';

import Classes from './BannerGalleryModal.scss';

const BannerGalleryModal = () => {
  useEffect(() => {
    displayBannerAfterInteraction(BANNER_POSITION.DYNAMIC.IMAGE);
  }, []);

  return <div id={bannerPositions[BANNER_POSITION.DYNAMIC.IMAGE].id} className={Classes.root} />;
};

export default BannerGalleryModal;
