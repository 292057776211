import React, { useState } from 'react';
import { TertiaryIconButton } from '@hz-design-system/web-ui';

import { BANNER_POSITION, bannerPositions } from '../../constants/banners';

import Classes from './BannerTop.scss';

const BannerDealerTop = () => {
  const [showBanner, setShowBanner] = useState(true);

  const handleClick = () => {
    setShowBanner(false);
  };

  if (!showBanner) {
    return null;
  }

  return (
    <div className={Classes.root}>
      <TertiaryIconButton className={Classes.buttonClose} name="close-small" aria-label="close" onClick={handleClick} />

      <div id={bannerPositions[BANNER_POSITION.DYNAMIC.DEALER_TOP].id} />
    </div>
  );
};

export default BannerDealerTop;
