import React, { useState, useContext } from 'react';
import { Trans } from '@ecg-marktplaats/js-react-i18n';
import { Icon, Link } from '@hz-design-system/web-ui';
import EnvironmentContext from '../../../../contexts/EnvironmentContext';
import { trackGAEvent } from '../../../../utils/gaTracking';

import Classes from './PopupLink.scss';

type TPopupLinkProps = {
  itemId: string;
};

let Popup;

const PopupLink = ({ itemId }: TPopupLinkProps) => {
  const {
    experiments: { adReportIframeEnabled },
  } = useContext(EnvironmentContext);
  const [isOpen, setIsOpen] = useState(false);
  const [isPopupLoaded, setIsPopupLoaded] = useState(false);

  const loadPopupComponent = async () => {
    if (adReportIframeEnabled) {
      Popup = (await import(/* webpackChunkName: "FlagAdIframe" */ '../FlagAdIframe/FlagAdIframe')).default;
    } else {
      Popup = (await import(/* webpackChunkName: "PopupComponent" */ '../Popup/Popup')).default;
    }
    setIsPopupLoaded(true);
  };

  const handleClick = () => {
    if (!isPopupLoaded) {
      trackGAEvent('TipAdClicked', itemId);
      loadPopupComponent();
    }
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Link onClick={handleClick}>
        <Icon name="alert" color="signal-link-default" className={Classes.linkIcon} />
        <Trans i18nKey="flagging.title" tagName="span" className={Classes.linkText} />
      </Link>

      {isPopupLoaded && isOpen && <Popup itemId={itemId} toggle={handleClick} />}
    </>
  );
};

export default PopupLink;
