import { useContext, useState } from 'react';
import EnvironmentContext from '../contexts/EnvironmentContext';

type TCustomIframeEvent = {
  data: {
    messageId: string;
    message: {
      [key: string]: any;
    };
  };
};

const LOCAL_STORAGE_KEY = 'ces_displayed_surveys';
const SURVEY_ID = 'ASQ_COMPLETED_SURVEY';
const SURVEY_ID_CARS = 'CARS_ASQ_COMPLETED_SURVEY';
const SEC = 1000;
const MINUTE = 60;
const HOUR = 60;
const DAY = 24;
const SURVEY_INTERVAL_DAYS = 90;

const defaultStyles = {
  display: 'block',
  width: '320px',
  height: '276px',
  zIndex: '1',
  border: '0',
  boxShadow: '0px 10px 50px -10px #000',
  borderRadius: '6px',
  background: '#fff',
  transition: 'all 0.3s',
};

const checkIfEnoughTimeHasPassed = (surveyId): boolean => {
  const storageItem = localStorage.getItem(LOCAL_STORAGE_KEY);
  if (storageItem) {
    try {
      const displayedSurveys = JSON.parse(storageItem);
      const displayedSurveyTimestamp = displayedSurveys[surveyId];
      const now = new Date().getTime();
      if (displayedSurveyTimestamp) {
        const diffTime = Math.abs(now - displayedSurveyTimestamp);
        const diffDays = Math.ceil(diffTime / (SEC * MINUTE * HOUR * DAY));

        if (diffDays < SURVEY_INTERVAL_DAYS) {
          return false;
        }
      }
    } catch (e) {
      return false;
    }
  }

  return true;
};

const close = (modalContainer) => {
  (modalContainer?.parentNode as HTMLElement).removeChild(modalContainer as HTMLElement);
};

const createModalContainer = () => {
  const container = document.createElement('div');
  container.innerHTML = `
      <div class="mp-iframe-wrapper">
        <div class="hz-Spinner">
          <div class="hz-Spinner-dots"></div>
        </div>
      </div>
    `;
  const styles = {
    display: 'flex',
    width: '100vw',
    height: '100vh',
    position: 'fixed',
    top: '0',
    left: '0',
    zIndex: '1',
    border: '0px',
    justifyContent: 'center',
    alignItems: 'center',
  };
  Object.keys(styles).forEach((key) => {
    container.style[key] = styles[key];
  });
  container.onclick = () => close(container);

  document.body.appendChild(container);
  return container;
};

const createIframe = (modal, url, id, styles) => {
  const element = document.createElement('iframe');
  element.setAttribute('src', url);
  element.setAttribute('id', id);
  element.style.visibility = 'hidden';
  Object.keys(styles).forEach((key) => {
    element.style[key] = styles[key];
  });
  (modal?.firstElementChild as HTMLElement).appendChild(element as HTMLIFrameElement);
  return element;
};

const setMessageListener = (iframe, iframeId, modalContainer) => {
  const respondToSizingMessage = (event: TCustomIframeEvent) => {
    if (event.data && event.data.messageId === iframeId) {
      const styles = event.data.message.styles || {};
      Object.keys(styles).forEach((key) => {
        iframe.style[key] = styles[key];
      });

      if (event.data.message.close) {
        close(modalContainer);
      }
    }
  };
  window.addEventListener('message', respondToSizingMessage, false);
};

const requestIframeStyles = (iframe, iframeId) => {
  iframe.contentWindow?.postMessage(
    {
      messageId: iframeId,
      message: 'style?',
    },
    window.location.origin,
  );
};

const setIframeLoadedListener = (iframe, modalContainer, iframeId) => {
  let notResponding = true;
  iframe?.contentWindow?.addEventListener('DOMContentLoaded', () => {
    const alert = modalContainer?.querySelector('.hz-Spinner');
    alert?.parentNode?.removeChild(alert);
    iframe.style.visibility = 'visible';
    requestIframeStyles(iframe, iframeId);
    notResponding = false;
  });
  setTimeout(() => {
    if (notResponding) {
      close(modalContainer);
    }
    // eslint-disable-next-line
  }, 3000);
};

const init = (url = '', id = '', styles = defaultStyles) => {
  const modal = createModalContainer();
  const iframe = createIframe(modal, url, id, styles);
  setMessageListener(iframe, id, modal);
  setIframeLoadedListener(iframe, modal, id);
};

const useCesSurvey = (isCarAd) => {
  const [surveyLoaded, setSurveyLoaded] = useState(false);
  const surveyId = isCarAd ? SURVEY_ID_CARS : SURVEY_ID;

  const {
    experiments: { isCesSurveyEnabled, isCesSurveyForCarsEnabled },
  } = useContext(EnvironmentContext);

  const loadSurvey = () => {
    if (!isCesSurveyEnabled && !isCarAd) {
      return;
    }
    if (!isCesSurveyForCarsEnabled && isCarAd) {
      return;
    }
    setSurveyLoaded(false);
    if (checkIfEnoughTimeHasPassed(surveyId)) {
      const IFRAME_URL = `${window.location.origin}/survey-web/main/${window.btoa(surveyId)}`;
      init(IFRAME_URL, 'SURVEY_WEB_ID');
      setSurveyLoaded(true);
    }
  };

  return {
    surveyLoaded,
    loadSurvey,
  };
};

export default useCesSurvey;
