import React from 'react';

import { BANNER_POSITION, bannerPositions } from '../../constants/banners';

import Classes from './BannerOutside.scss';

type TBannerOutsideProps = {
  bannerPositionId: string;
};

const BannerOutside = ({ bannerPositionId }: TBannerOutsideProps) => {
  const sideClassName =
    bannerPositionId === bannerPositions[BANNER_POSITION.STATIC.LEFT].id ? Classes.left : Classes.right;

  return (
    <div className={`${Classes.root} ${sideClassName}`}>
      <div id={bannerPositionId} />
    </div>
  );
};

export default BannerOutside;
