import React, { useContext, useEffect, useState } from 'react';
import {
  loadAdsenseSearch,
  TAdsenseSearchParams,
  ADSENSE_SEARCH_PAGE_TYPE,
  adsenseLoadedCallback,
} from '@ecg-marktplaats/ecg-js-adsense';

import EnvironmentContext from '../../../../contexts/EnvironmentContext';
import { ADSENSE_EVENTS } from '../../../../utils/constants';

import { TListingClient } from 'types/TListing';

import Classes from './AdsenseForSearch.scss';
import isMobile from '../../../../utils/isMobile';

type TAdsenseForSearchProps = {
  title: TListingClient['title'];
};

const AdsenseForSearch = ({ title }: TAdsenseForSearchProps) => {
  const {
    adsense: { searchClientId = '', channel = '', numRepeated = 0, adtest = '' } = {},
    tenantContext: { locale = '', tenant = '' } = {},
    isGooglePurposeAccepted,
  } = useContext(EnvironmentContext);

  const [isLoaded, setIsLoaded] = useState<boolean | undefined>();

  const bottomBlockContainerId = 'adsense-for-search';

  useEffect(() => {
    let styleId = '';

    if (tenant === 'mp') {
      styleId = isMobile() ? '1407626141' : '5957710024';
    } else {
      styleId = isMobile() ? '5964898986' : '4651816683';
    }

    const options: TAdsenseSearchParams = {
      pageOptions: {
        adtest,
        numRepeated,
        adPage: 1,
        hl: locale.split('-')[0],
        pubId: searchClientId,
        query: title,
        channel: channel,
        personalizedAds: isGooglePurposeAccepted,
        styleId,
      },
      pageType: ADSENSE_SEARCH_PAGE_TYPE.VIP,
      bottomBlockContainerId,
      adLoadedCallback: function adLoadedCallback(_containerName, adLoaded: boolean) {
        adsenseLoadedCallback(
          adLoaded,
          ADSENSE_EVENTS.ADSENSE_FOR_SEARCH_REQUESTED,
          ADSENSE_EVENTS.ADSENSE_FOR_SEARCH_LOADED,
        );

        setIsLoaded(adLoaded);
      },
    };

    try {
      loadAdsenseSearch(options);
    } catch (e) {}
  }, [adtest, numRepeated, locale, searchClientId, title, channel, isGooglePurposeAccepted, tenant]);

  return (
    <div className={`${Classes.root} ${isLoaded ? `${Classes.loaded} block-wrapper` : ''}`}>
      <div id={bottomBlockContainerId} />
    </div>
  );
};

export default AdsenseForSearch;
