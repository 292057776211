import React, { useContext } from 'react';

import { useI18nContext } from '@ecg-marktplaats/js-react-i18n';

import EnvironmentContext from '../../../../contexts/EnvironmentContext';
import PopupLink from '../PopupLink/PopupLink';

import { TListing } from 'types/TListing';

import Classes from './Report.scss';

type TReportProps = {
  itemId: TListing['itemId'];
};

const Report = ({ itemId }: TReportProps) => {
  const { t } = useI18nContext();
  const { userDetails: { isAdOwner = false } = {} } = useContext(EnvironmentContext);

  return (
    <div className={`block-wrapper-s ${Classes.container}`}>
      <span className={`${Classes.itemIdText}`}>{t('listing.itemId', { itemId })}</span>
      {!isAdOwner && (
        <div className={Classes.flaggingText}>
          <PopupLink itemId={itemId} />
        </div>
      )}
    </div>
  );
};

export default Report;
