import React from 'react';
import { Asset } from '@hz-design-system/web-ui';

import energyLabels from '../../../../../../../content/energyLabels/index.json';
import { TListingAttributeApi } from 'types/TListing';
import { TTenantContext } from 'types/TTenantContext';

type TEnergyLabelProps = {
  value: TListingAttributeApi['value'];
  tenantContext: TTenantContext;
  className?: string;
};

const EnergyLabel = ({ value, tenantContext: { tenant, locale }, className }: TEnergyLabelProps) => {
  const assetName = energyLabels[tenant][locale][value];

  return (
    <span className={className}>
      <Asset.Fluid name={assetName} className="EnergyLabel" />
    </span>
  );
};

export default EnergyLabel;
