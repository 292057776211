import React, { useContext, useState, useEffect, useRef } from 'react';
import { Trans, useI18nContext } from '@ecg-marktplaats/js-react-i18n';
import { SecondaryButton, ButtonIcon } from '@hz-design-system/web-ui';
import Cookies from 'js-cookie';

import EnvironmentContext from '../../../../contexts/EnvironmentContext';
import toggleFavouriteListing from '../../repositories/toggleFavouriteListing';
import LoginDialogContainer from '../../../../components/LoginDialog/LoginDialogContainer';
import { trackGAEvent } from '../../../../utils/gaTracking';

import { TListingClient } from 'types/TListing';

import Classes from './FavouriteButton.scss';

type TFavouriteButtonProps = {
  itemId: TListingClient['itemId'];
  isSaved: TListingClient['isSaved'];
};

const addToFavoritesBeginEvents = {
  label: 'WatchlistAddBegin',
  action: 'Top | Bottom',
};
const addToFavoritesSuccessEvents = {
  label: 'WatchlistAddSuccess',
  action: 'Top | Bottom',
};
const removeFromFavoritesBeginEvents = {
  label: 'WatchlistRemoveBegin',
  action: 'Top | Bottom',
};
const removeFromFavoritesSuccessEvents = {
  label: 'WatchlistRemoveSuccess',
  action: 'Top | Bottom',
};

const getGaEventsForBegin = (isFavorite) => (isFavorite ? removeFromFavoritesBeginEvents : addToFavoritesBeginEvents);
const getGaEventsForSuccess = (isFavorite) =>
  isFavorite ? removeFromFavoritesSuccessEvents : addToFavoritesSuccessEvents;

const trackGaEventForBegin = (isFavourite) => {
  const { label, action } = getGaEventsForBegin(isFavourite);
  trackGAEvent(label, action);
};

const trackGaEventForSuccess = (isFavourite) => {
  const { label, action } = getGaEventsForSuccess(isFavourite);
  trackGAEvent(label, action);
};

const FavouriteButton = ({ isSaved, itemId }: TFavouriteButtonProps) => {
  const { userLoggedIn, xsrfToken } = useContext(EnvironmentContext);
  const { t } = useI18nContext();
  const [isFavourite, setIsFavourite] = useState(isSaved);
  const [showLoginDialog, setShowLoginDialog] = useState(false);
  const closeToast = useRef<(id?: string | number) => void>();

  const toggleFavourite = async () => {
    const newState = !isFavourite;
    // optimistic update
    setShowLoginDialog(false);
    setIsFavourite(newState);

    trackGaEventForBegin(isFavourite);

    const [response, { default: showToastNotification, dismissToast }] = await Promise.all([
      toggleFavouriteListing({ itemId, favourite: newState, xsrfToken }),
      import(/* webpackChunkName: "toastNotification" */ '../../../../components/Toast/ToastNotification'),
    ]);

    closeToast.current = dismissToast;

    // success state
    if (isFavourite !== response.isFavourite) {
      trackGaEventForSuccess(isFavourite);

      showToastNotification({
        type: 'success',
        description: t(
          isFavourite ? 'listing.favourite.notifications.unfavoriting' : 'listing.favourite.notifications.favoriting',
        ),
      });
    } else {
      showToastNotification({
        type: 'error',
        description: t('error.generic'),
      });
    }

    // actual update
    setIsFavourite(response.isFavourite);
  };

  const handleFavouriteToggle = async () => {
    if (closeToast.current) {
      closeToast.current();
    }

    if (userLoggedIn) {
      await toggleFavourite();
    } else {
      setShowLoginDialog(true);
    }
  };

  const handleLoginSuccess = () => {
    Cookies.set('favoriteToggleReloaded', 'true');
    document.location.reload();
  };

  useEffect(() => {
    // if isAdOwner we remove the cookie before redirecting to SVIP in checkListingAvailabilityMiddleware.
    const favouriteFlag = Cookies.get('favoriteToggleReloaded');
    if (favouriteFlag) {
      handleFavouriteToggle();
      Cookies.remove('favoriteToggleReloaded');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <SecondaryButton className={Classes.root} onClick={handleFavouriteToggle}>
        <ButtonIcon name={isFavourite ? 'heart-filled' : 'heart'} isStartIcon={false} />
        <Trans
          className={Classes.text}
          tagName="span"
          i18nKey={isFavourite ? 'listing.favourite.saved' : 'listing.favourite.save'}
        />
      </SecondaryButton>
      {showLoginDialog && <LoginDialogContainer onClose={setShowLoginDialog} onSuccess={handleLoginSuccess} />}
    </>
  );
};

export default FavouriteButton;
